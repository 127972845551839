import { memo, useState, useMemo, useCallback, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import { Spin, Table } from 'antd'
import { v4 as uuidV4 } from 'uuid'
import { isEmpty, startCase, uniq } from 'lodash'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { mergeAddressShort } from 'App/components/Transport/Utils/jobHelper'
import { Address, Job, JobType, JobTypeRequiredFields, Trip } from 'App/types/graphql'
import { COMPANIES_QUERY, formatArrayString, defaultColumns } from './helper'
import useJobTypes from 'App/hooks/useJobTypes'

const JobsTable = memo((props: any) => {
  const { client, form, step } = props

  const [addressesMap, setAddressesMap] = useState(new Map())
  const [columns, setColumns] = useState<any>(defaultColumns)
  const { jobTypes, requestJobTypes } = useJobTypes(client)

  useEffect(() => {
    const requiredFields: any = []
    jobTypes.forEach((jt: JobType) => {
      // @ts-ignore
      jt.requiredFields?.forEach((rf: JobTypeRequiredFields) => {
        if (columns.find((column: any) => column.key === rf.name)) return

        if (rf.name?.startsWith('trip')) return

        columns.push({
          key: rf.name,
          title: startCase(rf.name || ''),
          render: (text: string, record: Job) => formatArrayString(record.details?.[rf.name || ''])
        })
      })

      setColumns(columns)
    })
  }, [jobTypes])

  const dataSource = useMemo(() => form.getFieldValue('jobs') || [], [form])

  const [getCompanies, { loading, error }] = useLazyQuery(COMPANIES_QUERY, {
    client,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (data?.companies?.rows) {
        const companies = data.companies.rows.reduce((acc: any, company: any) => {
          company.addresses.forEach((address: Address) => {
            acc.set(address.uuid, mergeAddressShort(address))
          })

          return acc
        }, new Map())

        setAddressesMap(companies)
      }
    }
  })

  const expandedRowRender = useCallback(
    (row) => {
      const defaultSubColumns = [
        {
          title: '',
          dataIndex: 'indentation',
          width: '42px',
          className: 'xs-font'
        },
        {
          title: 'Trip No',
          dataIndex: 'sequence',
          key: 'sequence',
          render: (text: string, record: Trip, index: number) => {
            return index + 1
          }
        },
        {
          title: 'From',
          dataIndex: 'from',
          key: 'from',
          render: (text: string, record: Trip, index: number) => {
            return addressesMap.get(record.fromUuid)
          }
        },
        {
          title: 'To',
          dataIndex: 'to',
          key: 'to',
          render: (text: string, record: Trip, index: number) => {
            return addressesMap.get(record.toUuid)
          }
        }
      ]

      jobTypes.forEach((jobType: any) => {
        jobType.requiredFields.forEach((field: JobTypeRequiredFields) => {
          if (field.name?.startsWith('trip')) {
            const trimTrip = field.name?.slice(4)
            const fieldKey = trimTrip.charAt(0).toLowerCase() + trimTrip.slice(1)

            defaultSubColumns.push({
              title: startCase(field.name?.replace('trip', '') || ''),
              dataIndex: field.name,
              key: field.name,
              render: (text: string, record: any, index: number) => {
                return formatArrayString(record[fieldKey])
              }
            })
          }
        })
      })

      const data = row?.trips?.map((t: Trip, index: number) => ({ key: index, ...t }))

      return <Table size="small" columns={defaultSubColumns} dataSource={data} pagination={false} />
    },
    [addressesMap]
  )

  useEffect(() => {
    if (step === 2 && dataSource?.[0]?.trips?.length) {
      requestJobTypes(uniq(dataSource.map((job: Job) => job.type)))

      const companyUuids = dataSource.reduce((uuids: string, job: any) => {
        const tripCompanyUuids: any = []

        job.trips.forEach((trip: any) => {
          tripCompanyUuids.push(trip.fromCompanyUuid)
          tripCompanyUuids.push(trip.toCompanyUuid)
        })

        return uniq(uuids.concat(tripCompanyUuids.filter(Boolean)))
      }, [])

      getCompanies({
        variables: {
          statuses: ['activated'],
          uuids: companyUuids,
          limit: 50
        }
      })
    }
  }, [dataSource, step])

  if (error) {
    logger.error('JobsTable COMPANIES_QUERY error', error)
    respHandler(error, 'error')
  }

  if (isEmpty(addressesMap) || isEmpty(dataSource) || isEmpty(jobTypes)) {
    return <Spin />
  }

  return (
    <Table
      key="loading-not-done"
      size="small"
      rowKey={uuidV4}
      // @ts-ignore
      columns={columns}
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      // expandedRowKeys={dataSource?.map((d, i) => i + 1)}
      expandRowByClick={true}
      defaultExpandAllRows={true}
      expandedRowRender={expandedRowRender}
    />
  )
})

export default withApollo(JobsTable)
