import { useMutation, useQuery } from '@apollo/client'
import { List, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useBookingStore } from 'App/store/booking'
import respHandler from 'App/utils/responseHandler'
import { useClient } from 'App/hooks/useClient'
import { DOCUMENT_GENERATOR_TEMPLATES_QUERY, GENERATE_DOCUMENT } from '../schema'
import DocumentGeneratorTemplateCard from './DocumentCreatorTemplateCard'

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`

interface TemplateSelectionListProps {
  handleInitialDocumentGenerated: (generatedDocument: any) => void
}

const TemplateSelectionList = (props: TemplateSelectionListProps) => {
  const { handleInitialDocumentGenerated } = props

  const { t } = useTranslation()

  const bookingUuidForDocumentGenerator = useBookingStore.use.bookingUuidForDocumentGenerator()
  const client = useClient()

  const [generateDocument] = useMutation(GENERATE_DOCUMENT, {
    client
  })

  const handleClick = async (documentGeneratorTemplate: any) => {
    try {
      const { data } = await generateDocument({
        variables: {
          input: {
            groups: [bookingUuidForDocumentGenerator ?? ''],
            code: documentGeneratorTemplate.code
          }
        }
      })

      handleInitialDocumentGenerated(data?.generateDocument)
    } catch (error) {
      respHandler(error, 'error')
    }
  }

  const {
    data,
    loading,
    error,
    refetch: documentCreatorTemplatesRefetch
  } = useQuery(DOCUMENT_GENERATOR_TEMPLATES_QUERY, {
    client
  })

  const handlePageChange = (page, pageSize) => {
    documentCreatorTemplatesRefetch({
      limit: pageSize,
      offset: (page - 1) * pageSize
    })
  }

  if (error) {
    // @ts-ignore
    const errorCodes = error.graphQLErrors.map((e) => e.extensions.exception.statusCode)

    if (errorCodes.includes(403)) {
      return <span>{t('error.permission.general')}</span>
    }

    return <span>{t('common.errorOccurred')}</span>
  }

  const { rows = [], pageInfo } = data?.documentGeneratorTemplates || {}

  const pagination = pageInfo && {
    total: pageInfo.count ?? 0,
    pageSize: pageInfo.limit ?? 0,
    current: pageInfo.offset ?? 0 / (pageInfo.limit ?? 0) + 1,
    onChange: handlePageChange
  }

  return (
    <Spin spinning={loading && !rows}>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={rows || []}
        pagination={pagination}
        renderItem={(item) => (
          <StyledListItem>
            <DocumentGeneratorTemplateCard documentGeneratorTemplate={item} onClick={handleClick} />
          </StyledListItem>
        )}
      />
    </Spin>
  )
}

export default TemplateSelectionList
