import { Row, Col, Icon, Popconfirm, Tooltip } from 'antd'
import { useCallback, memo, useMemo, useEffect } from 'react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { withApollo } from 'react-apollo'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import { BookingDetails } from './Styled'
import responseHandler from 'App/utils/responseHandler'

const { Header, Description, Section } = BookingDetails

const CREATE_TRANSPORT_RATE_FROM_BOOKING = gql`
  mutation createTransportRateFromBooking($uuid: UUID) {
    createTransportRateFromBooking(uuid: $uuid) {
      success
    }
  }
`

const RECOMPUTE_COST_SHEET = gql`
  mutation recomputeCostFromVoucherCostSheet($uuid: UUID) {
    recomputeCost(uuid: $uuid) {
      success
    }
  }
`

const COMPUTED_COST_LAST_NODE = gql`
query computedCostLastNode($uuid: UUID){
  computedCostLastNode(uuid: $uuid)
}
`

const VoucherBookingDetails = memo((props) => {
  const { voucherBooking, refetch, client } = props
  const { quotation, costItems } = voucherBooking
  const costItemUuids = useMemo(() => costItems.map((item) => item.uuid), [costItems])
  const q = useMemo(
    () => queryString.stringify({ costItemUuids }, { arrayFormat: 'comma' }),
    [queryString, costItemUuids]
  )
  const [createTransportRate, { loading }] = useMutation(CREATE_TRANSPORT_RATE_FROM_BOOKING, {
    client
  })
  const [recomputeCostSheet, { loading: recomputeCostSheetLoading }] = useMutation(
    RECOMPUTE_COST_SHEET,
    { client }
  )

  const [getComputedCostLastNode, { loading: computedCostLastNodeLoading, data: computedCostLastNodeData }] = useLazyQuery(
    COMPUTED_COST_LAST_NODE,
    { client }
  )

  const handleCreateTransportRate = useCallback(async () => {
    try {
      const res = await createTransportRate({ variables: { uuid: voucherBooking.uuid } })
      if (res?.data?.createTransportRateFromBooking?.success) {
        responseHandler('Successfully created transport rate.', 'success')
      }
    } catch (error) {
      responseHandler(error, 'error')
    }
  }, [createTransportRate, voucherBooking.uuid])

  const handleRecomputeCostSheet = useCallback(async () => {
    try {
      const res = await recomputeCostSheet({ variables: { uuid: voucherBooking.uuid } })
      if (res?.data?.recomputeCost?.success) {
        responseHandler('Successfully created transport rate.', 'success')
      }
      refetch()
    } catch (error) {
      responseHandler(error, 'error')
    }
  }, [createTransportRate, voucherBooking.uuid])

  const handleTraverseNode = useCallback(async ()=>{
    try {
      await getComputedCostLastNode({ variables: { uuid: voucherBooking.uuid } })
    } catch (error) {
      responseHandler(error, 'error')
    }
  }, [voucherBooking.uuid])

  useEffect(() => {
    if (computedCostLastNodeLoading || !computedCostLastNodeData) return

    const nodes =computedCostLastNodeData.computedCostLastNode

    if (!nodes.length) {
    return responseHandler('No Last Nodes Found', 'error')
    }

    const node = nodes[0]

    window.open(`/manage/rates/${node.rootId}?searchNodeId=${node.nodeId}`, '_blank')
  }, [computedCostLastNodeData])


  return (
    <>
      <Row type="flex" justify="space-between">
        <Col>
          <Section>
            <Header>Quotation Description : </Header>
            <Description>
              {quotation?.description || '-'}
              {quotation && (
                <a
                  href={`/manage/quotations/${quotation.uuid}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >{` (${quotation.quotationNo})`}</a>
              )}
            </Description>
          </Section>
        </Col>

        <Col style={{ textAlign: 'right' }}>
        <Tooltip title="Traverse Node">
            <Popconfirm
              okText="Yes"
              disabled={computedCostLastNodeLoading}
              title="Traverse Node?"
              onConfirm={handleTraverseNode}
            >
              <Icon type="search" style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }} />
            </Popconfirm>
          </Tooltip>

          <Tooltip title="Recompute Cost Sheet.">
            <Popconfirm
              okText="Yes"
              disabled={recomputeCostSheetLoading}
              title="Recompute Cost Sheet?"
              onConfirm={handleRecomputeCostSheet}
            >
              <Icon type="retweet" style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }} />
            </Popconfirm>
          </Tooltip>

          <Tooltip title="Create a transport rate from this booking">
            <Popconfirm
              okText="Yes"
              disabled={loading}
              title="Create transport rate?"
              onConfirm={handleCreateTransportRate}
            >
              <Icon type="dollar" style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }} />
            </Popconfirm>
          </Tooltip>

          <Link
            target="_blank"
            title="Integration Logs"
            to={`/manage/integration-logs?${q}`}
            style={{ color: 'rgb(150,150,150)', marginRight: '0.5rem' }}
          >
            <Icon type="exception" />
          </Link>

          <Link
            to={`/bookings/${voucherBooking.uuid}/cost-sheet`}
            target="_blank"
            style={{ color: 'rgb(150,150,150)' }}
            title="Cost Sheet"
          >
            <Icon type="file-text" />
          </Link>
        </Col>
      </Row>
    </>
  )
})

export default withApollo(VoucherBookingDetails)
