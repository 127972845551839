import { memo, useState, useCallback, useMemo } from 'react'
import { Button, Divider, Empty, Row, Skeleton, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import { sortBy } from 'lodash'

import JobsTable from './JobsTable'
import JobsMaps from 'App/components/Transport/Components/VehicleMap/JobsMaps'
import { ErrorWrapper, NoPermissionWrapper } from 'App/components/TableView/Styled'
import AddPlanVehicle from 'App/components/Transport/Planning/Vehicle/AddPlanVehicle'
import { getUserPreferenceValue } from 'App/components/Manage/UserSettings/UserSettingsForm'
import JobActivityModal from 'App/components/Transport/Components/Modals/JobActivityModal/JobActivityModal'

import { logger } from 'App/utils/logger'
import { formatLegsData } from './helpers'
import useGlobalCompanyStore from 'App/store/globalCompany'
import useProcessPortalUser from 'App/hooks/useProcessPortalUser'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'

import { BOOKING_TRANSPORT_JOBS_QUERY } from 'App/components/Transport/Schemas/schema'
import { USER_PREFERENCE_QUERY } from 'App/components/Booking/NewBookingForm/_shared/schema'

import { BookingStatus, type TransportJob } from 'App/types/graphql'
import enums from 'App/types/enums'

const BookingJobsTable = memo(({ booking, client, query, refetchBooking, loggedInUser }: any) => {
  const { t } = useTranslation()

  const { isPortalUser } = useProcessPortalUser(loggedInUser)
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [visible, setVisible] = useState(false)
  const [selectedLeg, setSelectedLeg] = useState<any>()
  const [refetchTrips, setRefetchTrips] = useState(false)

  const { loading: permLoadingJob, hasPermission: hasJobPermission } = usePermissionIsAllowed({
    client,
    resource: 'job',
    permission: 'view'
  })
  const { loading: permLoadingLegs, hasPermission: hasLegsPermission } = usePermissionIsAllowed({
    client,
    resource: 'transport:job',
    permission: 'index'
  })
  const { loading: prefLoading, error: prefError, data: prefData } = useQuery(USER_PREFERENCE_QUERY, {
    client,
    variables: {
      type: enums.UserPreference.Type.SETTINGS,
      key: enums.Vehicle.Display.key
    },
    fetchPolicy: 'cache-and-network'
  })
  const { data, error: jobError, loading: jobLoading, refetch: refetchJob } = useQuery(BOOKING_TRANSPORT_JOBS_QUERY, {
    client,
    variables: {
      input: {
        limit: 100,
        jobUuids: booking?.jobs?.map((j: any) => j.uuid)
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const refetchJobsDelayed = useCallback(
    debounce(async () => {
      setTimeout(() => {
        try {
          if (refetchJob) {
            refetchJob()
          }
          if (refetchBooking) {
            refetchBooking()
          }
        } catch (error) {
          // it's ok to fail when the JobActivityModal is already unmounted
        }
      }, 500)
    }, 500),
    [refetchBooking, refetchJob]
  )

  const openLegsModal = useCallback((data: any) => {
    if (isPortalUser) return // Block from opening

    setRefetchTrips(false)
    setSelectedLeg(data)
    setVisible(true)
  }, [isPortalUser])

  const handleModalCancel = useCallback(() => {
    setVisible(false)
    if (refetchTrips) {
      refetchJobsDelayed()
    }
  }, [refetchTrips, refetchJobsDelayed])

  const newSelectedLeg = useMemo(
    () => data?.transportJobs?.rows?.find((job: any) => job.legUuid === selectedLeg?.legUuid),
    [data, selectedLeg]
  )

  const vehicleCodeOrReg = useMemo(
    () =>
      getUserPreferenceValue(
        enums.UserPreference.Type.SETTINGS,
        enums.Vehicle.Display.key,
        prefData
      ),
    [prefData]
  )

  const tableReadyData = useMemo(() => {
    return formatLegsData(data?.transportJobs?.rows, vehicleCodeOrReg)
  }, [data, vehicleCodeOrReg])

  const legUuids = useMemo(() => data?.transportJobs?.rows?.map((j: any) => j.legUuid), [data])

  const groupedJobs = useMemo(() => {
    const t = (data?.transportJobs?.rows || []).reduce((acc: Record<string, TransportJob[]>, tj: TransportJob) => {
      if (!tj?.start) return acc

      const found = acc[tj?.jobNo || '']

      if (!Array.isArray(found)) {
        acc[tj.jobNo || ''] = []
      }

      acc[tj.jobNo || ''].push(tj)

      return acc
    }, {})

    return sortBy(Object.values(t), (t) => t?.[0].jobNo)
  }, [data])

  if (permLoadingJob || permLoadingLegs || prefLoading || jobLoading) {
    return <Skeleton active />
  }

  if (!hasJobPermission || !hasLegsPermission) {
    return <NoPermissionWrapper />
  }

  const gqlError = prefError || jobError

  if (gqlError) {
    logger.error('BookingJobsTable BOOKING_TRANSPORT_JOBS_QUERY error', gqlError)
    return <ErrorWrapper>{t('common.failedToLoadPleaseTryAgainLater')}</ErrorWrapper>
  }

  if (!booking?.jobs?.length || !data?.transportJobs?.rows?.length) {
    return <Empty description={t('common.noData')} />
  }

  const jobTripUuids = groupedJobs.map((gj: any) => ({ tripUuids: gj.map(t => t.tripUuid) }))

  return (
    <Row style={{ paddingBottom: 15 }}>
      <>
        <Divider>
          <Row style={{ display: 'inline' }}>
            {t('transport.jobsTripsLegs')}
            <Tooltip title={t('transport.refreshTrips')}>
              <Button
                icon="sync"
                style={{ marginLeft: 20 }}
                onClick={refetchJobsDelayed}
                loading={prefLoading || jobLoading}
              />
            </Tooltip>
          </Row>
        </Divider>

        <div style={{ marginBottom: 10 }}>
          <AddPlanVehicle {...{ legUuids, isValidForUpdate: booking?.status === BookingStatus.Accepted, isSelectedTable: 'LEG_BOOKING_PAGE' }} />
        </div>

        <JobsTable
          data={tableReadyData}
          readOnly={isPortalUser}
          openLegsModal={openLegsModal}
        />

        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10, marginTop: 10 }}>
          <JobsMaps jobTripUuids={jobTripUuids} client={client} showScrollZoom={false} showNavigationControl={true} />
        </div>
      </>

      {visible && (
        <JobActivityModal
          // @ts-ignore
          visible={visible}
          leg={newSelectedLeg}
          onCancel={handleModalCancel}
          setRefetchTrips={setRefetchTrips}
          vehicleCodeOrReg={vehicleCodeOrReg}
          userBaseCompany={selectedGlobalCompany}
          query={{ startDate: query.startDate, endDate: query.endDate }}
        />
      )}
    </Row>
  )
})

export default withApollo(BookingJobsTable)
