import { memo } from 'react'
import styled from 'styled-components'
import { Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import ExportBookingList from 'App/components/Booking/ListView/Export'

import type { Booking } from 'App/types/graphql'

const TableHeader = styled.div`
  display: flex;
  padding: 5px 0;
  margin: 0 20px;
  flex-direction: row;
  justify-content: space-between;
`

const BookingsListView = (props: any) => {
  const { t } = useTranslation()

  const { total, columns, loading, results, pageSize, currentPage, handlePageChange, handleSizeChange } = props

  const pagination = {
    total,
    showSizeChanger: true,
    pageSize: pageSize || 10,
    current: currentPage + 1,
    onChange: handlePageChange,
    onShowSizeChange: handleSizeChange,
    pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
  }

  return (
    <>
      <TableHeader>
        <Typography.Text strong type="secondary">
          {t('index.totalFound')} : {total}
        </Typography.Text>

        <ExportBookingList {...props} />
      </TableHeader>

      <Table
        size="small"
        loading={loading}
        columns={columns}
        dataSource={results}
        pagination={pagination}
        style={{ overflowX: 'scroll' }}
        rowKey={(record: Booking) => record.uuid}
        onRow={(_, index) => {
          return { style: { cursor: 'pointer' }, id: `booking-row-${index}` }
        }}
      />
    </>
  )
}

export default memo(BookingsListView)
