import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon, Popover, Modal } from 'antd';

import * as bookingDocumentActions from 'App/states/reducers/bookingDocument';
import EmptyAnchor from '../../EmptyAnchor';
import { StyledMenu, StyledMenuItem, StyledOptions } from './Styled';
import schema from 'App/containers/booking/schema';
import BookingDocumentQuery from 'App/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery';
import { DELETE_BOOKING_DOCUMENT } from '../Content/schema';
import { getBkQueryVariables } from 'App/components/Booking/bookingHelper';

const { confirm } = Modal;

const HeaderOptions = ({ bookingDocument }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { params } = useParams();

  const [deleteDocument] = useMutation(DELETE_BOOKING_DOCUMENT, {
    refetchQueries: [
      {
        query: schema.BOOKING_QUERY,
        variables: getBkQueryVariables(params),
      },
      {
        query: BookingDocumentQuery,
        variables: { uuid: bookingDocument.uuid },
      },
    ],
    onCompleted: (result) => handleDeleted(result),
  });

  const handleDelete = () => {
    setVisible(false);
    confirm({
      title: 'Confirm to delete this document?',
      content: 'All changes or links to this document will be gone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDocument({
          variables: {
            uuid: bookingDocument.uuid,
          },
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleted = ({ deleteBookingDocument }) => {
    dispatch(
      bookingDocumentActions.updateSelectedBookingDocument({
        ...deleteBookingDocument,
        allowedApprovals: bookingDocument.allowedApprovals,
      })
    );
  };

  const renderOptions = () => {
    return (
      <StyledMenu>
        <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}`}>
          View in new tab
        </StyledMenuItem>
        <StyledMenuItem
          target="_blank"
          href={`/booking/document/${bookingDocument.uuid}?tag=1`}
        >
          View Tagged in new tab
        </StyledMenuItem>
        {bookingDocument.status === 'DELETED' ? null : (
          <StyledMenuItem onClick={handleDelete}>Delete document</StyledMenuItem>
        )}
      </StyledMenu>
    );
  };

  return (
    <StyledOptions>
      <Popover
        trigger="click"
        placement="bottomRight"
        content={renderOptions()}
        getPopupContainer={(trigger) => trigger.parentNode}
        visible={visible}
      >
        <EmptyAnchor>
          <Icon type="ellipsis" onClick={() => setVisible(!visible)} style={{ cursor: 'pointer' }} />
        </EmptyAnchor>
      </Popover>
    </StyledOptions>
  );
};

export default HeaderOptions;
