import { Form } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { clone } from 'lodash'
import { useEffect } from 'react'
import styled from 'styled-components'

import { useBookingStore } from 'App/store/booking'
import { GeneratedDocumentQuery, GeneratedDocumentStatus } from 'App/types/graphql'
import DataInput from './DataInput'

const StyledDiv = styled.div`
  .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      line-height: 30px;
    }
  }

  overflow-y: scroll;
  min-height: 200px;
  max-height: 550px;
  border: solid 1px lightgray;
  padding: 0px 10px;
`

interface DataFormProps {
  form: WrappedFormUtils
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
}

const DataForm = (props: DataFormProps) => {
  const { form, generatedDocument } = props

  const setDataForm = useBookingStore.use.setDocumentGeneratorDataForm()
  const resetDataForm = useBookingStore.use.resetDocumentGeneratorDataForm()

  useEffect(() => {
    setDataForm(form)

    return () => {
      resetDataForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clonedData = clone(generatedDocument?.data || undefined)
  const dataEntries = Object.entries(clonedData)

  return (
    <StyledDiv>
      <Form>
        {dataEntries?.map((e) => {
          const disabled = generatedDocument?.status !== GeneratedDocumentStatus.Draft
          const field = generatedDocument?.documentTemplate?.fieldsObject[e[0]]

          return (
            <DataInput
              form={form}
              key={e[0]}
              entry={e}
              customValues={field?.apiFormatCustomValues}
              isAutoPopulate={field?.isAutoPopulate}
              enumName={field?.enumName}
              type={field?.type}
              query={field?.query}
              companyTypes={field?.companyTypes}
              control={field?.required}
              isMultiple={field?.multiSelect}
              entryMeta={generatedDocument?.dataMeta[e[0]]}
              disabled={disabled}
            />
          )
        })}
      </Form>
    </StyledDiv>
  )
}

export default Form.create<DataFormProps>()(DataForm)
