import { formatVehicle } from 'App/components/Transport/Components/VehicleSelector'
import {
  getBookingInfo,
  formatLegTimings,
  getBkDetailsForPlanning,
} from 'App/components/Transport/Utils/jobHelper'
import { getDriverName } from 'App/components/Transport/Planning/Jobs/helpers/getTableAccessors'

import type { TransportJob } from 'App/types/graphql'

export const formatLegsData = (legs: Array<TransportJob>, vehicleCodeOrReg: string) => {
  const formattedLegs = legs
    ?.map((leg: TransportJob) => {
      const bookingInfo = getBookingInfo(leg)
      const bookingPlanning = getBkDetailsForPlanning(leg)
      const legTimings = formatLegTimings(leg)

      return {
        ...leg,
        PM: formatVehicle(leg, vehicleCodeOrReg),
        DR: getDriverName(leg),
        ...bookingInfo,
        ...bookingPlanning,
        ...legTimings
      }
    })
    ?.sort((a: any, b: any) => a.sequence - b.sequence)
    ?.sort((a: any, b: any) => a.tripSequence - b.tripSequence)
    ?.sort((a: any, b: any) => a.jobNo?.localeCompare(b.jobNo))

  return formattedLegs
}
