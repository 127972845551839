import { memo } from 'react'
import { Tooltip, Icon } from 'antd'

import bookingStatus from 'App/utils/bookingStatus'

import type { Booking } from 'App/types/graphql'

type BookingStatusIconProps = {
  booking: Booking
}

const BookingStatusIcon = ({ booking }: BookingStatusIconProps) => {
  const currentStatus = bookingStatus[booking.status] || bookingStatus.default

  const style = {
    color: currentStatus.color,
    backgroundColor: currentStatus.backgroundColor
  }

  return (
    <Tooltip title={currentStatus.tips}>
      <Icon type={currentStatus.icon} style={style} />
    </Tooltip>
  )
}

export default memo(BookingStatusIcon)
