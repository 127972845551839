import { TransportSource } from 'App/types/graphql'
import {
  getBookingInfo,
  getBkDetailsForPlanning,
  formatLegTimings
} from 'App/components/Transport/Utils/jobHelper'
import { formatVehicle } from 'App/components/Transport/Components/VehicleSelector'

export const getDriverNamePhone = (leg: any) => {
  if (!leg?.driverEntity?.name && !leg?.driverName) {
    return '-'
  }

  if (leg.transportSource && leg.transportSource !== TransportSource.Own) {
    return leg?.driverName || leg?.driverEntity?.name
  }

  const driverPhone = leg?.driverEntity?.contact?.[0]?.number
  return `${leg?.driverEntity?.name || '-'}${driverPhone ? ` - ${driverPhone}` : ''}`
}

export const getDriverName = (leg: any) => {
  if (leg.driverName) {
    return `${leg.driverCode} / ${leg.driverName}`
  }

  if (leg.driverEntity?.name) {
    return `${leg.driverEntity?.code} / ${leg.driverEntity?.name}`
  }

  return '-'
}

export const getTableAccessors = (
  legs: any,
  baseCompanies: any,
  vehicleCodeOrReg: string,
  query: any
) => {
  const formattedLegs = legs?.map((leg: any) => {
    const currentBaseCompany = baseCompanies?.find((company: any) => company.uuid === leg.ownerUuid)

    const bookingInfo = getBookingInfo(leg, false)
    const bookingPlanning = getBkDetailsForPlanning(leg, query)
    const legTimings = formatLegTimings(leg)

    return {
      ...leg,
      PM: formatVehicle(leg, vehicleCodeOrReg),
      DR: getDriverName(leg),
      ...bookingInfo,
      ...bookingPlanning,
      ...legTimings,
      baseCompany: currentBaseCompany?.shortCode || currentBaseCompany?.name || '-'
    }
  })

  return formattedLegs
}
