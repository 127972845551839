import { forwardRef, useCallback, useState, useEffect, memo, useMemo } from 'react'
import { Select } from 'antd'
import { withApollo } from 'react-apollo'
import { gql, useLazyQuery } from '@apollo/client'
import debounce from 'lodash/debounce'

import { isUuid } from 'App/utils/u'
import { logger } from 'App/utils/logger'
import { Job } from 'App/types/graphql'
import responseHandler from 'App/utils/responseHandler'

const Option = Select.Option

type TransportJobSelectorProps = {
  client?: any
  value?: any
  onChange?: any
  mode?: string | undefined | null
  bookingUuids?: Array<string>
  jobUuids?: Array<string>
  queryOnMount?: boolean
  style?: any
}

export const JOBS_SELECTOR_QUERY = gql`
  query jobsSelectEs($input: JobsSearchInput) {
    jobsSearchJson(input: $input) {
      rows
      # rows {
      #   uuid
      #   jobNo
      #   bookingUuid
      # }
    }
  }
`

const TransportJobSelector = forwardRef((props: TransportJobSelectorProps, ref: any) => {
  const {
    client,
    value,
    onChange,
    mode,
    bookingUuids,
    jobUuids,
    queryOnMount = false,
    style
  } = props

  const [jobs, setJobs] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState('')

  const input = useMemo(
    () => ({
      q: (!mode && !isUuid(value) && value) || searchInput || '',
      filter: {
        jobUuids:
          !searchInput && jobUuids?.filter(Boolean)?.length ? jobUuids?.filter(Boolean) : null,
        bookingUuid: bookingUuids?.filter(Boolean)?.length ? bookingUuids?.filter(Boolean) : null
      },
      limit: 20
    }),
    [mode, jobUuids, value, searchInput, bookingUuids]
  )

  const [getJobs, { data, error, loading }] = useLazyQuery(JOBS_SELECTOR_QUERY, {
    client,
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = useCallback(
    debounce((value: string, options: any) => {
      try {
        setSearchInput(value)
        getJobs()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (queryOnMount || value) {
      getJobs()
    }
  }, [])

  useEffect(() => {
    if (setJobs) {
      setJobs(data?.jobsSearchJson?.rows)
    }
  }, [data])

  if (error) {
    logger.error('TransportJobSelector TRANSPORT_JOBS_SELECTOR_QUERY error', error)
    responseHandler(error, 'error')
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getJobs()
  }

  return (
    <Select
      allowClear
      filterOption={false}
      loading={loading}
      // @ts-ignore
      mode={mode}
      onFocus={fetchOnFocus}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      onChange={onChange}
      // @ts-ignore
      onSearch={handleSearch}
      placeholder="Search a job here..."
      ref={ref}
      showSearch
      value={value}
      style={style}
    >
      {jobs?.map((job: Job) => (
        <Option key={job.uuid} value={job.uuid}>
          {[job.jobNo, job.no].filter(Boolean).join(' - ')}
        </Option>
      ))}
    </Select>
  )
})

// @ts-ignore
export default withApollo(memo(TransportJobSelector))
