import { ApolloClient } from '@apollo/client'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { UploadFile } from 'antd/lib/upload/interface'
import createSelectors from 'App/store/createSelectors'
import { Booking, Maybe } from 'App/types/graphql'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type BulkUploadDataSource = {
  key: string
  booking: Booking | undefined
  bookingNo: Maybe<string> | undefined
  file: UploadFile<any>
  document: any
  fileName: string
  status: 'UPLOADED' | 'APPROVED' | 'NOT STARTED' | 'FAILED' | 'READY'
  message: string
}

type BookingState = {
  selectedBillToCompany: string | undefined
  setSelectedBillToCompany: (company: string) => void
  resetSelectedBillToCompany: () => void

  tripDynamicFields: any[]
  setTripDynamicFields: (fields: any) => void
  resetTripDynamicFields: () => void

  hideDeletedTrip: boolean
  setHideDeletedTrip: (check: boolean) => void

  hideDeletedJob: boolean
  setHideDeletedJob: (check: boolean) => void

  expandTrips: boolean
  setExpandTrips: (check: boolean) => void

  selectedFileBulkUpload: UploadFile<any>[]
  setSelectedFileBulkUpload: (files: UploadFile<any>[]) => void
  fileBulkUploadDataSource: BulkUploadDataSource[]
  setFileBulkUploadDataSource: (files: BulkUploadDataSource[]) => void

  bookingUuidForDocumentGenerator: string | undefined
  setBookingUuidForDocumentGenerator: (uuid: string) => void
  resetBookingUuidForDocumentGenerator: () => void
  apolloClientForDocumentGeneratorGetter: () => ApolloClient<any> | undefined
  setApolloClientForDocumentGeneratorGetter: (client: ApolloClient<any>) => void
  resetApolloClientForDocumentGeneratorGetter: () => void
  documentGeneratorModalGeneratedDocument: any
  setDocumentGeneratorModalGeneratedDocument: (value: any) => void
  resetDocumentGeneratorModalGeneratedDocument: () => void

  documentGeneratorTableRefetch: () => any
  setDocumentGeneratorTableRefetch: (refetch: () => any) => void
  resetDocumentGeneratorTableRefetch: () => void

  documentGeneratorModalRefetch: () => any
  setDocumentGeneratorModalRefetch: (refetch: () => any) => void
  resetDocumentGeneratorModalRefetch: () => void

  documentGeneratorAssociatedBookingsForm: WrappedFormUtils
  setDocumentGeneratorAssociatedBookingsForm: (form: any) => void
  resetDocumentGeneratorAssociatedBookingsForm: () => void

  documentGeneratorDataForm: WrappedFormUtils
  setDocumentGeneratorDataForm: (form: any) => void
  resetDocumentGeneratorDataForm: () => void

  bookingCollapse: boolean
  setBookingCollapse: (check: boolean) => void
}

const nonPersistkey = [
  'selectedBillToCompany',
  'tripDynamicFields',
  'selectedFileBulkUpload',
  'fileBulkUploadDataSource',
  'documentGenerator'
]

export const useBookingStore = createSelectors(
  create<BookingState>()(
    persist(
      (set) => ({
        selectedBillToCompany: undefined,
        setSelectedBillToCompany: (company: string) => set({ selectedBillToCompany: company }),
        resetSelectedBillToCompany: () => set({ selectedBillToCompany: undefined }),

        tripDynamicFields: [],
        setTripDynamicFields: (fields: any) => set({ tripDynamicFields: fields }),
        resetTripDynamicFields: () => set({ tripDynamicFields: [] }),

        hideDeletedTrip: false,
        setHideDeletedTrip: () => set((state) => ({ hideDeletedTrip: !state.hideDeletedTrip })),
        hideDeletedJob: false,
        setHideDeletedJob: () => set((state) => ({ hideDeletedJob: !state.hideDeletedJob })),
        expandTrips: false,
        setExpandTrips: () => set((state) => ({ expandTrips: !state.expandTrips })),

        selectedFileBulkUpload: [],
        setSelectedFileBulkUpload: (files) => {
          const dataSource: BulkUploadDataSource[] = files.map((file) => ({
            file,
            key: file.uid,
            bookingNo: '-',
            booking: undefined,
            document: undefined,
            fileName: file.name,
            message: '-',
            status: 'NOT STARTED'
          }))

          set({ fileBulkUploadDataSource: dataSource })
          set({ selectedFileBulkUpload: files })
        },
        fileBulkUploadDataSource: [],
        setFileBulkUploadDataSource: (files) => set({ fileBulkUploadDataSource: files }),

        bookingUuidForDocumentGenerator: undefined,
        setBookingUuidForDocumentGenerator: (uuid) =>
          set({ bookingUuidForDocumentGenerator: uuid }),
        resetBookingUuidForDocumentGenerator: () =>
          set({ bookingUuidForDocumentGenerator: undefined }),

        apolloClientForDocumentGeneratorGetter: () => undefined,
        setApolloClientForDocumentGeneratorGetter: (client) =>
          set({ apolloClientForDocumentGeneratorGetter: () => client }),
        resetApolloClientForDocumentGeneratorGetter: () =>
          set({ apolloClientForDocumentGeneratorGetter: () => undefined }),

        documentGeneratorTableRefetch: () => {},
        setDocumentGeneratorTableRefetch: (refetch) =>
          set({ documentGeneratorTableRefetch: refetch }),
        resetDocumentGeneratorTableRefetch: () => set({ documentGeneratorTableRefetch: () => {} }),

        documentGeneratorModalRefetch: () => {},
        setDocumentGeneratorModalRefetch: (refetch) =>
          set({ documentGeneratorModalRefetch: refetch }),
        resetDocumentGeneratorModalRefetch: () => set({ documentGeneratorModalRefetch: () => {} }),

        documentGeneratorModalGeneratedDocument: {},
        setDocumentGeneratorModalGeneratedDocument: (value) =>
          set({ documentGeneratorModalGeneratedDocument: value }),
        resetDocumentGeneratorModalGeneratedDocument: () =>
          set({ documentGeneratorModalGeneratedDocument: {} }),

        // @ts-ignore
        documentGeneratorAssociatedBookingsForm: undefined,
        setDocumentGeneratorAssociatedBookingsForm: (form) =>
          set({ documentGeneratorAssociatedBookingsForm: form }),
        resetDocumentGeneratorAssociatedBookingsForm: () =>
          set({ documentGeneratorAssociatedBookingsForm: undefined }),

        // @ts-ignore
        documentGeneratorDataForm: undefined,
        setDocumentGeneratorDataForm: (form) => set({ documentGeneratorDataForm: form }),
        resetDocumentGeneratorDataForm: () => set({ documentGeneratorDataForm: undefined }),

        bookingCollapse: false,
        setBookingCollapse: () => set((state) => ({ bookingCollapse: !state.bookingCollapse }))
      }),
      {
        name: 'bookings-storage',
        partialize: (state) =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !nonPersistkey.includes(key)))
      }
    )
  )
)
