import { useQuery } from '@apollo/client'
import { Modal, Spin } from 'antd'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBookingStore } from 'App/store/booking'
import { GeneratedDocumentQuery } from 'App/types/graphql'
import { useClient } from 'App/hooks/useClient'
import { GENERATED_DOCUMENT_QUERY } from '../../../schema'
import DocumentCreatorModalContent from './ModalContent'
import ModalFooter from './ModalFooter'

interface DocumentCreatorModalProps {
  afterClose?: () => void
  generatedDocumentUuid: string
}

const DocumentCreatorModal = (props: DocumentCreatorModalProps) => {
  const { afterClose = () => {}, generatedDocumentUuid } = props

  const { t } = useTranslation()

  const [visible, setVisible] = useState(true)
  const [generatedDocument, setGeneratedDocument] = useState<
    GeneratedDocumentQuery['generatedDocument'] | undefined
  >(undefined)

  const client = useClient()

  const { data, loading, error, refetch } = useQuery(GENERATED_DOCUMENT_QUERY, {
    variables: {
      uuid: generatedDocumentUuid
    },
    client
  })

  useEffect(() => {
    setGeneratedDocument(data?.generatedDocument || undefined)
  }, [data])

  const handleChange = async () => {
    const { data } = await refetch()
    setGeneratedDocument(data?.generatedDocument || undefined)
  }

  const setDocumentGeneratorModalRefetch = useBookingStore.use.setDocumentGeneratorModalRefetch()
  const resetDocumentGeneratorModalRefetch =
    useBookingStore.use.resetDocumentGeneratorModalRefetch()

  useEffect(() => {
    setDocumentGeneratorModalRefetch(refetch)

    return () => {
      resetDocumentGeneratorModalRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // @ts-ignore
    <Modal
      style={{ top: 20 }}
      destroyOnClose
      title={t('booking.documentGenerator.generic')}
      width='97%'
      visible={visible}
      footer={
        <Spin spinning={loading}>
          <ModalFooter
            generatedDocument={generatedDocument}
            onChange={handleChange}
            onClose={() => setVisible(false)}
          />
        </Spin>
      }
      onCancel={() => setVisible(false)}
      afterClose={afterClose}
    >
      {error && <span>{t('common.errorOccurred')}</span>}

      {loading && <span>{t('common.loading')}</span>}

      {!error && !isEmpty(generatedDocument) && (
        <DocumentCreatorModalContent generatedDocument={generatedDocument} />
      )}
    </Modal>
  )
}

export default DocumentCreatorModal
