import { Col, Icon } from 'antd'
import { useState } from 'react'
import { documentType } from 'App/utils/labelMap'
import { StyledSelector, ExpandableRow, TypeName } from './Styled'
import HiddenView from './Hidden'

const statusIcons = {
  pending: 'clock-circle',
  approved: 'check-circle'
}

const ApprovalSelector = ({ approvalType, isApproved, approvedApproval }) => {

  const statusIcon = isApproved ? statusIcons.approved : statusIcons.pending
  const [showHidden, toggleHidden] = useState(false)
  const toggleHiddenFields = () => toggleHidden(!showHidden)

  return (
    <StyledSelector>
    <ExpandableRow type="flex" align="middle" onClick={toggleHiddenFields}>
      <Col span={2}>
        <Icon type={statusIcon} />
      </Col>
      <Col span={21}>
        <TypeName>{documentType[approvalType] || approvalType}</TypeName>
      </Col>
      <Col span={1}>
        <Icon type={showHidden ? 'up' : 'down'} />
      </Col>
    </ExpandableRow>
    <HiddenView
      showHidden={showHidden}
      isApproved={isApproved}
      approvalType={approvalType}
      approvedApproval={approvedApproval}
      toggleHiddenFields={toggleHiddenFields}
    />
  </StyledSelector>
  )
}
export default ApprovalSelector