import { forwardRef, useState, useEffect, useMemo, memo, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import debounce from 'lodash/debounce'
import { Select } from 'antd'

import { logger } from 'App/utils/logger'

import type { DocumentNode } from 'graphql'
import {
  JobTypeStatus,
  JobOptionStatus,
  VolumeUnitStatus,
  WeightUnitStatus,
  BillingUnitStatus,
  BookingTypeStatus,
  ContainerTypeStatus,
  ContainerGradeStatus,
  TransportJobTypeStatus
} from 'App/types/graphql'

const defaultDisplayOptions = {
  label: 'name',
  key: 'code',
  value: 'code'
}

type DisplayOption = {
  label: string
  key: string
  value: string
  statues: ['ACTIVE']
}

export enum Mode {
  DEFAULT = 'default',
  MULTIPLE = 'multiple',
  TAGS = 'tags'
}

const lookup: any = {
  billingUnits: {
    statuses: [BillingUnitStatus.Activated]
  },
  bookingTypes: {
    statuses: [BookingTypeStatus.Active]
  },
  jobTypes: {
    statuses: [JobTypeStatus.Active]
  },
  transportJobTypes: {
    statuses: [TransportJobTypeStatus.Active]
  },
  containerTypes: {
    statuses: [ContainerTypeStatus.Active]
  },
  containerGrades: {
    statuses: [ContainerGradeStatus.Active]
  },
  weightUnits: {
    statuses: [WeightUnitStatus.Active]
  },
  volumeUnits: {
    statuses: [VolumeUnitStatus.Active]
  },
  jobOptions: {
    statuses: [JobOptionStatus.Active]
  }
}

const getDisplayOptions = (options: any, type: string): any => {
  return {
    ...defaultDisplayOptions,
    ...lookup[type],
    ...options
  }
}

type DynamicTransportSelectProps = {
  client?: any
  value?: any
  onChange?: any
  disabled?: boolean
  style?: any
  query: DocumentNode
  type: string
  mode?: Mode
  displayOptions?: DisplayOption
  selectOptions?: string[] | undefined
  searchText?: string
  queryOnMount?: boolean
  defaultActiveFirstOption?: boolean,
  queryVariables?: any
}

const DynamicTransportSelect = forwardRef((props: DynamicTransportSelectProps, ref: any) => {
  const {
    client,
    value,
    onChange,
    disabled = false,
    style,
    query,
    type,
    mode = 'default',
    searchText = '',
    displayOptions,
    queryOnMount = false,
    defaultActiveFirstOption = false,
    selectOptions,
    queryVariables
  } = props

  // @ts-ignore
  const { label, value: dataValue, key, statuses } = { ...getDisplayOptions(displayOptions, type) }

  const [types, setTypes] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const params = useMemo(
    () => ({
      q: searchInput || '',
      limit: 20,
      statuses
    }),
    [value, searchInput]
  )

  const [getData, { data, error, loading }] = useLazyQuery(query, {
    client,
    variables: { ...params },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (queryOnMount || value) {
      getData()
    }
  }, [])

  useEffect(() => {
    if (queryVariables) {
      getData({ variables: { ...queryVariables } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables])

  useEffect(() => {
    const dataRows = data?.[type]?.rows
    if (queryVariables && dataRows && dataRows.length === 0) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables, data])

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getData()
      } catch (error) {
        logger.error(`Dynamic Transport Select ${type} error when searching`, error)
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (data?.[type]?.rows) {
      const rows = selectOptions?.length
        ? data[type].rows.filter((row: any) => selectOptions.includes(row[key]))
        : data[type].rows

      setTypes(rows)
      if (rows.length && defaultActiveFirstOption && !value) {
        onChange(rows[0][dataValue])
      }
    }
  }, [data])

  useEffect(() => {
    if (!data?.[type]?.rows) {
      return
    }

    const rows = selectOptions?.length
      ? data[type].rows.filter((row: any) => selectOptions.includes(row[key]))
      : data[type].rows

    setTypes(rows)
  }, [selectOptions])

  if (error) {
    //@ts-ignore
    if (error.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 404) {
      logger.error(`Dynamic Transport Select ${type} error`, error)
    }
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getData()
  }

  return (
    <Select
      id={`form-${type}-selector`}
      mode={mode}
      ref={ref}
      allowClear
      showSearch
      value={value}
      loading={loading}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      onChange={onChange}
      disabled={disabled}
      style={{ width: '100%', ...style }}
      filterOption={false}
      placeholder={searchText}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {types?.map((type: any) => (
        <Select.Option key={type[key]} value={type[dataValue]}>
          {type[label]}
        </Select.Option>
      ))}
    </Select>
  )
})

export default withApollo(memo(DynamicTransportSelect))
