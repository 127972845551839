import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { stringify } from 'query-string'
import { Button, Col, Row } from 'antd'

import UserTags from './Tags'
import Selections from './Selections'

import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import { getBookingQuery } from 'App/components/Layouts/Search/helper'

import { Wrapper } from './Styled'
import type { User } from 'App/types/graphql'

const UserFilter = () => {
  const history = useHistory()
  const [editMode, setEditMode] = useState(false)

  const [selectedUserUuids, setSelectedUserUuids] = useState<any>([])

  const generateQuery = (selectedKeys: string[]) => {
    const { urlFormat } = getBookingQuery(history, { filter: { userUuids: selectedKeys } })
    history.push(`/?${stringify(urlFormat)}`)
  }

  useEffect(() => {
    const storedAssignee = webStorage.getItem(LOCAL_STORAGE_KEYS.ASSIGNEES)
    if (storedAssignee) {
      setSelectedUserUuids(storedAssignee)
      const selectedKeys = storedAssignee.map((item: User) => item.uuid)
      generateQuery(selectedKeys)
    }
  }, [])

  const focusToField = () => {
    setEditMode(true)
  }

  const focusOutField = () => {
    setEditMode(false)
  }

  const handleSelectDeselect = (item: User[]) => {
    setSelectedUserUuids(item)
    const selectedKeys = item.map((item: User) => item.uuid)
    webStorage.setItem(LOCAL_STORAGE_KEYS.ASSIGNEES, item)
    generateQuery(selectedKeys)
  }

  const handleClearAll = () => {
    webStorage.setItem(LOCAL_STORAGE_KEYS.ASSIGNEES, [])
    setSelectedUserUuids([])
    generateQuery([])
  }

  if (!editMode) {
    return (
      <Row type="flex" align="middle" style={{ gap: 10 }}>
        <UserTags selectedUserUuids={selectedUserUuids} focusToField={focusToField} />
        {!!selectedUserUuids.length && <Button onClick={handleClearAll}>Clear All</Button>}
      </Row>
    )
  }

  return (
    <Wrapper>
      <Row type="flex" align="middle">
        <Col span={20}>
          <Selections focusOutField={focusOutField} handleSelectDeselect={handleSelectDeselect} />
        </Col>
      </Row>
    </Wrapper>
  )
}
export default UserFilter
