import { useState, useEffect, useCallback, memo } from 'react'
import { Form, Button, Row, Typography , Checkbox, Col } from 'antd'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import styled from 'styled-components'

import useJobTypes from 'App/hooks/useJobTypes'

import FormSteps from './FormSteps'
import SubmitAction from './SubmitAction'
import ButtonLayout from './ButtonLayout'
import VisibilityDiv from './VisibilityDiv'
import DefaultActions from './DefaultAction'
import ConfirmationSubForm from './ConfirmationSubForm'
import HiddenFieldsSubForm from './HiddenFieldsSubForm'
import ShipperConsigneeSubForm from './ShipperConsigneeSubForm'
import TransportDetailsSubForm from './TransportDetailsSubForm'

import { useBookingStore } from 'App/store/booking'

const StyledDiv = styled.div`
  padding-bottom: 42px;
  .formSteps {
    margin-bottom: 24px;
  }
`

const getTripsErrorMessage = (errors) => {
  const messages = []
  errors.forEach((subArr) => {
    subArr.forEach((item) => {
      if (item) {
        Object.values(item).forEach((errorObj) => {
          if (errorObj.errors && errorObj.errors.length > 0) {
            messages.push(errorObj.errors[0].message)
          }
        })
      }
    })
  })
  return messages
}

const getErrorMessages = (errors) => {
  const messages = []
  const traverseErrors = (obj) => {
    for (const key in obj) {
      const error = obj[key]
      if (error?.errors && error?.errors?.length > 0) {
        messages.push(error.errors?.[0].message)
      } else if (typeof error === 'object') {
        traverseErrors(error)
      }
    }
  }
  traverseErrors(errors)
  return messages
}

const CreateBkErr = ({ jobErr }) => {
  return <Typography.Text style={{ color: 'red' }}>{jobErr}</Typography.Text>
}

const NewBookingTransportForm = memo((props) => {
  const { history, form, client } = props
  const { jobTypes, requestJobTypes } = useJobTypes(client)

  const [step, setStep] = useState(0)
  const [jobErr, setJobErr] = useState('')
  const [bookingUuid, setBookingUuid] = useState('')
  const [showJobErr, setShowJobErr] = useState(false)
  const [isOverrideBlockDuplicate, setIsOverrideBlockDuplicate] = useState(false)
  const resetSelectedBillToCompany = useBookingStore(state => state.resetSelectedBillToCompany)

  const onBack = useCallback(() => {
    if (step) {
      setStep(step - 1)
    }
  }, [step])

  useEffect(() => {
    if (bookingUuid.length > 0) {
      history.push(`/bookings/${bookingUuid}`)
    }
  }, [bookingUuid, history])

  useEffect(() => {
    requestJobTypes()

    return () => resetSelectedBillToCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelectedBillToCompany])

  const checkRequiredFields = () => {
    if (step === 0) {
      form.validateFields((err) => {
        if (err) {
          setShowJobErr(true)
          const errorMessages = getErrorMessages(err).join(', ')
          setJobErr(errorMessages)
        } else {
          setShowJobErr(false)
          setStep(step + 1)
        }
      })
    }

    form.getFieldValue('jobs').forEach(async (job, jobIndex) => {
      if (!jobTypes.length) {
        await requestJobTypes()
      }

      if (step === 1) {
        const checkRequiredFields = jobTypes?.find((type) => type?.code === job?.type)

        if (!checkRequiredFields) {
          setShowJobErr(true)
          return setJobErr(`Job #${jobIndex + 1}:Type is required`)
        }
        const defaultRequiredFields = ['type']

        const detailInputFields = checkRequiredFields.requiredFields
          .filter((f) => f.control === 'REQUIRED')
          .map((f) => f.name)
          .concat(defaultRequiredFields)

        // get job details error messages
        const validateDetailJob = Object.entries(job.details || {}).map(([key, value]) => {
          if (detailInputFields.includes(key) && !value) {
            return { [key]: { errors: [{ message: `Job #${jobIndex + 1}:${key} is required` }] } }
          }
          return null
        })

        const tripsInputFields = ['fromCompanyUuid', 'fromUuid', 'toCompanyUuid', 'toUuid']
        // get job trips errror messages
        const validateTrips = job.trips.map((trip, tripIndex) => {
          return Object.entries(trip).map(([key, value]) => {
            if (tripsInputFields.includes(key) && !value) {
              return {
                [key]: { errors: [{ message: `Trip #${tripIndex + 1}: ${key} is required` }] }
              }
            }
            return null
          })
        })

        const detailErrorMessage = getErrorMessages(validateDetailJob)
        const tripsErrorMessage = getTripsErrorMessage(validateTrips)

        if (detailErrorMessage.length || tripsErrorMessage.length) {
          setShowJobErr(true)
          const errorMessages = [...detailErrorMessage, ...tripsErrorMessage].join(', ')
          return setJobErr(errorMessages)
        }
        setShowJobErr(false)
        setStep(step + 1)
      }
    })
  }

  const StepButtons = () => (
    <ButtonLayout>
      {showJobErr && <CreateBkErr jobErr={jobErr} />}

      <DefaultActions form={form} />

      {step !== 0 && (
        <Button icon="left" onClick={onBack}>
          Back
        </Button>
      )}

      <Button id='create-booking-stepper-button' icon="right" type="primary" onClick={checkRequiredFields}>
        Next
      </Button>
    </ButtonLayout>
  )

  const handleOverrideBlockDuplicate = (e) => {
    setIsOverrideBlockDuplicate(e.target.checked)
  }

  return (
    <StyledDiv>
      <Row className="formSteps">
        <FormSteps currentStep={step} />
      </Row>

      <Form>
        <HiddenFieldsSubForm form={form} />
        <VisibilityDiv visible={step === 0}>
          <ShipperConsigneeSubForm {...props} />
          <StepButtons />
        </VisibilityDiv >

        <VisibilityDiv visible={step === 1}>
          <TransportDetailsSubForm {...props} />
          <StepButtons />
        </VisibilityDiv >

        <VisibilityDiv visible={step === 2}>
          <ConfirmationSubForm form={form} step={step} />
            <Row type="flex" style={{ marginTop: '80px' }} justify="end" align="middle" gutter={1}>
              <Col key={'1'} style={{ gap: '5', cursor: 'pointer'}} onClick={()=>   setIsOverrideBlockDuplicate((prev)=> !prev)}>
              Override Duplicate Booking  <Checkbox checked={isOverrideBlockDuplicate} value={isOverrideBlockDuplicate} onChange={handleOverrideBlockDuplicate} />
              </Col>
            </Row>
          <ButtonLayout>
            <Button onClick={onBack}>Back</Button>
            <SubmitAction form={form} onSuccess={(uuid) => setBookingUuid(uuid)} isOverrideBlockDuplicate={isOverrideBlockDuplicate} />
          </ButtonLayout>
        </VisibilityDiv>
      </Form >
    </StyledDiv >
  )
})

export default withApollo(withRouter(Form.create()(NewBookingTransportForm)))
