import { Incentive } from 'App/types/graphql'
import { Icon, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

type Props = {
  incentives: Incentive[]
  incentiveLoading: boolean
}

const IncentiveDetails = ({ incentives, incentiveLoading }: Props) => {
  const items = [
    {
      label: 'Voucher',
      value: (incentive: Incentive) => incentive?.incentiveVoucher?.voucherNo
    },
    {
      label: 'Status',
      value: (incentive: Incentive) => incentive?.status
    },
    {
      label: 'Amount',
      value: (incentive: Incentive) => (incentive?.amount ? `RM ${incentive?.amount}` : '')
    },
    {
      label: 'Shift#',
      value: (incentive: Incentive) =>
        incentive.shift?.start || incentive?.shift?.end
          ? `${incentive.shift.start || ''}->${incentive.shift.end || ''}`
          : ''
    }
  ]

  if (incentiveLoading) {
    return <Icon type="loading" />
  }

  return (
    <div>
      <Typography.Text strong type="secondary">
        {incentives.length ? 'Incentives:' : 'No incentives found'}
      </Typography.Text>
      <table
        style={{ borderCollapse: 'collapse', width: '100%', fontSize: '10px' }}
        title="Incentive"
      >
        <thead>
          <tr>
            {incentives?.length ? (
              items.map((item, index) => (
                <th
                  key={index}
                  style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '2px' }}
                >
                  {item.label}
                </th>
              ))
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {incentives?.length ? (
            incentives?.map((incentive, rowIndex) => (
              <tr key={rowIndex}>
                {items.map((item, colIndex) => (
                  <td
                    key={colIndex}
                    style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '2px' }}
                  >
                    {item.value(incentive)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default IncentiveDetails
