import { useMutation } from '@apollo/client'
import { Button, message } from 'antd'
import { uniq } from 'lodash'
import { memo, useCallback, useEffect } from 'react'
import { withApollo } from 'react-apollo'

import useJobTypes from 'App/hooks/useJobTypes'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import convertValuesToInput from './convertValuesToInput'
import makeBookingGql from './makeBookingGql'

const SubmitAction = memo((props: any) => {
  const { form, client, onSuccess = () => {}, isOverrideBlockDuplicate } = props

  const [makeBooking, { loading }] = useMutation(makeBookingGql, { client })
  const { requestJobTypes, jobTypes } = useJobTypes(client)

  useEffect(() => {
    const jobTypesKeys = uniq(form.getFieldValue('jobs').map((job) => job.type)).filter(
      Boolean
    ) as string[]
    requestJobTypes(jobTypesKeys)
  }, [form.getFieldValue('type'), form.getFieldValue('jobs')])

  const handleClick = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (!values.details) values.details = {}

      if (err) return

      values.isOverrideBlockDuplicate = isOverrideBlockDuplicate
      try {
        const input = convertValuesToInput(values, jobTypes)
        const { data } = await makeBooking({ variables: { input } })
        onSuccess(data.makeBooking.uuid)
      } catch (error: any) {
        logger.error('SubmitAction makeBookingGql error', error)
        if (error?.message?.includes('Not allowed to create booking as with this Company')) {
          message.error(error.message)
        } else {
          respHandler(error, 'error')
        }
      }
    })
  }, [form, jobTypes, makeBooking, onSuccess, isOverrideBlockDuplicate])

  return (
    <Button id='submit-new-booking-button' type='primary' loading={loading} onClick={handleClick}>
      Submit
    </Button>
  )
})

export default withApollo(SubmitAction)
