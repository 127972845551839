import { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { withApollo, graphql } from 'react-apollo'
import { gql } from '@apollo/client'
import { connect } from 'react-redux'
import { Form, message } from 'antd'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import omit from 'lodash/omit'
import find from 'lodash/find'
import pick from 'lodash/pick'
import uuidv4 from 'uuid/v4'

import { withBooking } from 'App/contexts/booking'
import UserQuery from 'App/containers/user/query/user'
import createVoucherMutation from 'App/containers/voucher/mutation/createVoucher'
import updateVoucherMutation from 'App/containers/voucher/mutation/updateVoucher'
import updateVoucherItemMutation from 'App/containers/voucher/mutation/updateVoucherItem'
import { calculateVoucherItem, calculateVoucher } from '@shipx/formula2'
import { approveVoucherWithRefetchBooking } from 'App/containers/voucher/mutation/approveVoucher'
import { changeVoucherStatusWithBookingRefetch } from 'App/containers/voucher/mutation/changeVoucherStatus'
import unlinkDocumentFromVoucherMutation from 'App/containers/voucher/mutation/unlinkDocumentFromVoucher'
import { deleteVoucherItemWithRefetchBooking } from 'App/containers/voucher/mutation/deleteVoucherItem'
import linkDocumentToVoucherMutation from 'App/containers/voucher/mutation/linkDocumentToVoucher'
import { addVoucherItemWithRefetchBooking } from 'App/containers/voucher/mutation/addVoucherItem'
import voucherRequiredDataSchema from 'App/containers/bulk/voucherRequiredData/schema'
import { getExistingCompanyGql } from 'App/utils/importExport/companies/schema'
import addPaymentMutation from 'App/containers/voucher/mutation/addPayment'
import voucherQuerySchema from 'App/containers/voucher/schema/voucherQuery'
import { getExchangeRate, getLocalExchangeRate } from '../Utils'
import * as currencyActions from 'App/states/reducers/currency'
import { getGqlResponse } from 'App/utils/importExport/helpers'
import * as voucherActions from 'App/states/reducers/voucher'
import { recalibrateVoucherItem } from 'App/utils/voucher'
import handleResponse from 'App/utils/responseHandler'
import Schema from 'App/containers/booking/schema'
import { JobStatus, CompanyStatus } from 'App/types/graphql'
import { BOOKING_SEARCH_QUERY } from './schema'
import { logger } from 'App/utils/logger'
import { VoucherTypeType } from 'App/types/graphql'
import { UPDATE_VOUCHER_ITEM } from 'App/graphql/voucher'
import { sortVoucherItems } from 'App/utils/voucher'

const fullBookingQuery = graphql(Schema.BOOKING_VOUCHER_COST_SHEET, {
  name: 'fullBookingQuery',
  skip: (props) => !props.bookingQuery,
  options: (props) => ({
    variables: {
      uuid: props.bookingQuery?.booking?.uuid
    },
    fetchPolicy: 'cache-and-network'
  })
})

const delayRefetchBooking = (fullBookingQuery, bookingUuid) => {
  if (!fullBookingQuery?.refetch || !bookingUuid) {
    return
  }
  setTimeout(() => {
    try {
      fullBookingQuery.refetch({
        uuid: bookingUuid
      })
    } catch (error) {
      // ok if the voucher modal has already unmounted
      console.error('withInBookingPage error', error)
    }
  }, 1000)
}

const jobsByBookingUuid = {}

const VOUCHER_JOBS_QUERY = gql`
  query actualizeCostItemJobs($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        bookingUuid
        details
        jobNo
        no
        status
        uuid
      }
    }
  }
`

const voucherJobs = graphql(VOUCHER_JOBS_QUERY, {
  name: 'voucherJobs',
  // In case you are wondering why skip is commented out, it's called magic.
  // Unfortunately we don't know. But this happens when you actualize a booking
  // with only one job. Without this, the jobUuid will show up.

  // skip: (props) => !props.bookingQuery?.variables?.uuid,
  options: (props) => ({
    variables: {
      bookingUuid: props.bookingQuery?.booking?.uuid
    },
    fetchPolicy: 'cache-and-network'
  }),
  skip: (props) => !props.bookingQuery?.booking?.uuid
})

export default (WrappedComponent) => {
  class WithVoucher extends Component {
    componentWillUnmount() {
      const { updateSelectedVoucherBooking } = this.props
      updateSelectedVoucherBooking('')
    }

    componentDidMount() {
      this.loadRequiredData()
    }

    loadRequiredData = async (existVoucher) => {
      const {
        client,
        updateTaxes,
        toggleLoader,
        selectedVoucher,
        updateCurrencies,
        updateVoucherBookings,
        updateSelectedVoucher,
        toggleVoucherBookingLoader,
        updateSelectedVoucherBooking,
        updateSelectedVoucherTemplate
      } = this.props

      if (selectedVoucher?.status !== 'NEW') {
        toggleVoucherBookingLoader(true)

        const voucher = existVoucher || (await this.getVoucher(selectedVoucher?.uuid))

        const mappedVoucherItems = voucher?.voucherItems?.map((vi) => {
          return {
            ...vi,
            bookingUuid: vi.bookingUuid || vi?.costItem?.bookingUuid
          }
        })
        const voucherBookings = uniqBy(mappedVoucherItems, 'bookingUuid') || []

        if (voucher?.bookings?.length) {
          updateVoucherBookings(voucher.bookings)
          updateSelectedVoucherBooking(voucher.bookings[0].uuid)
          toggleVoucherBookingLoader(false)
        } else if (voucherBookings.length) {
          voucherBookings.map(async (vb) => {
            try {
              const { data } = await client.query({
                query: BOOKING_SEARCH_QUERY,
                variables: {
                  input: {
                    q: vb.bookingUuid,
                    sort: '',
                    limit: 20,
                    offset: 0,
                    filter: {},
                    _noSubGraph: true
                  }
                }
              })
              updateVoucherBookings(data?.bookingsSearchJson?.rows)
              if (data?.bookingsSearchJson?.rows?.[0]) {
                updateSelectedVoucherBooking(data?.bookingsSearchJson?.rows?.[0]?.uuid)
              }
              toggleVoucherBookingLoader(false)
            } catch (error) {
              logger.error('Voucher Modal BOOKING_SEARCH_QUERY error', error)
              handleResponse(error, 'error')
            }
          })
        }
        updateSelectedVoucher(voucher)
      }

      try {
        const { data } = await client.query({
          query: voucherRequiredDataSchema,
          variables: {
            resource: 'voucher',
            voucherType: selectedVoucher?.type,
            bookingType:
              this.props?.booking?.type ||
              existVoucher?.bookings?.[0].type ||
              this.props.selectedVoucher?.bookings[0]?.type
          }
        })

        toggleLoader(false)

        if (data) {
          updateTaxes(data.taxes && data.taxes.rows)
          updateCurrencies(data.currencies && data.currencies.rows)
          updateSelectedVoucherTemplate(data.actionTemplate)
        }
      } catch (error) {
        logger.error('Voucher Modal voucherRequiredDataSchema error', error)
        handleResponse(error, 'error')
      }
    }

    approveVoucher = async (type) => {
      const {
        booking,
        fullBookingQuery,
        approveVoucher,
        selectedVoucher,
        updateSelectedVoucher,
        selectedVoucherBooking,
        updateVoucherSubmitting
      } = this.props

      if (type) {
        updateVoucherSubmitting(true)
        handleResponse('Approving voucher, hang on...', 'load')

        try {
          const resp = await approveVoucher(
            {
              voucherUuid: selectedVoucher?.uuid,
              type
            },
            selectedVoucherBooking
          )

          if (resp?.data) {
            delayRefetchBooking(fullBookingQuery, booking?.uuid)

            const updatedVoucher = await this.getVoucher(selectedVoucher.uuid, {
              fetchPolicy: 'network-only'
            })
            const voucherInfo = updatedVoucher.voucherNumber || updatedVoucher.uuid.substring(0, 8)

            updateSelectedVoucher(updatedVoucher)

            handleResponse(`Voucher ${voucherInfo} has been approved.`, 'success')
          }
          updateVoucherSubmitting(false)
        } catch (error) {
          updateVoucherSubmitting(false)
          handleResponse(error, 'error')
          logger.error(`approveVoucher error uuid='${selectedVoucher?.uuid}'.`, error)
        }
      } else {
        handleResponse('Voucher type required.', 'warning')
      }
    }

    onHandleSubmit = async (alsoSubmit = false) => {
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          message.error('Please fill in all required fields.', 5)

          return
        }

        handleResponse('Creating voucher, hang on...', 'load')

        const {
          booking,
          fullBookingQuery,
          createVoucher,
          selectedVoucher,
          changeVoucherStatus,
          updateSelectedVoucher,
          selectedVoucherBooking,
          updateVoucherSubmitting,
          selectedGlobalCompany
        } = this.props

        updateVoucherSubmitting(true)
        const isAP = selectedVoucher.transactionType === VoucherTypeType.Accpay
        const checkCompanyUuid = isAP ? values?.vendorUuid : values?.customerUuid
        if (checkCompanyUuid) {
          try {
            const results = await getGqlResponse(selectedGlobalCompany, getExistingCompanyGql, {
              uuid: checkCompanyUuid
            })
            const company = results?.data?.company

            if (!company || company?.status !== CompanyStatus.Activated) {
              updateVoucherSubmitting(false)
              return handleResponse(
                `${isAP ? 'Pay To' : 'Bill To'} company="${checkCompanyUuid}" does not exist.`,
                'error'
              )
            }

            if (
              (values?.customerUuid && !company?.debtorCode) ||
              (values?.vendorUuid && !company?.creditorCode)
            ) {
              updateVoucherSubmitting(false)
              return handleResponse(
                `${isAP ? 'Pay To' : 'Bill To'} company="${company.name}" does not have a ${isAP ? 'creditor' : 'debtor'
                } code.`,
                'error'
              )
            }

            if (
              (isAP && values?.term && Number(values?.term) > company?.creditorTerm) ||
              (!isAP && values?.term && Number(values?.term) > company?.debtorTerm)
            ) {
              updateVoucherSubmitting(false)
              return handleResponse(
                `The term="${values?.term}" should be equal to or less than the ${isAP ? 'Pay To' : 'Bill To'
                } company's ${isAP ? 'creditor' : 'debtor'} term of ${isAP ? company?.creditorTerm : company?.debtorTerm
                }.`,
                'error'
              )
            }
          } catch (error) {
            logger.error(
              'voucher ModalView Container onHandleSubmit getExistingCompanyGql error',
              error
            )
            updateVoucherSubmitting(false)
            return handleResponse(error, 'error')
          }
        }

        const bookingDocumentUuids = selectedVoucher.bookingDocuments.map((doc) => doc.uuid)
        const voucherItems = selectedVoucher.voucherItems
          .filter((vi) => !vi.isDeleted)
          .map((item, index) => {
            const returnValues = {
              ...pick(item, [
                'size',
                'taxCode',
                'taxUuid',
                'quantity',
                'baseRate',
                'description',
                'bookingUuid',
                'exchangeRate',
                'localExchangeRate',
              ]),
              costItemUuid: item.costItem.uuid,
              currencyUuid: (item.currency && item.currency.uuid) || selectedVoucher.currencyUuid,
              sorting: index
            }

            if (item.jobUuid) {
              returnValues.jobUuid = item.jobUuid
            }

            if (item.voucherItemCnUuid) {
              returnValues.voucherItemCnUuid = item.voucherItemCnUuid
            }

            return returnValues
          })

        let updatedValues = omit(values, 'companyDescription')

        if (!updatedValues.addressUuid) {
          updatedValues = omit(updatedValues, 'addressUuid')
        }

        const createVoucherRequest = {
          ...updatedValues,
          uuid: selectedVoucher.uuid,
          term: parseInt(values.term),
          type: selectedVoucher.type,
          issueDate: updatedValues.issueDate && updatedValues.issueDate.startOf('day'),
          accountDate: updatedValues.accountDate && updatedValues.accountDate.startOf('day'),
          paymentType: values.paymentType !== 'CREDIT' ? 'CASH' : 'CREDIT',
          isCreditNote: selectedVoucher.isCreditNote || false,
          overrideDoubleReference: selectedVoucher.overrideDoubleReference,
          overrideDoubleVoucher: selectedVoucher.overrideDoubleVoucher,
          overrideIncompleteDocs: selectedVoucher.overrideIncompleteDocs,
          bookingDocumentUuids,
          voucherItems
        }

        try {
          const resp = await createVoucher(createVoucherRequest)

          let newVoucher = {}

          if (resp.data?.createVoucher) {
            if (alsoSubmit) {
              newVoucher = {
                ...resp.data.createVoucher
              }
              delayRefetchBooking(fullBookingQuery, booking?.uuid)
              updateSelectedVoucher({ ...selectedVoucher, ...newVoucher })

              const respStatus = await changeVoucherStatus(
                {
                  uuid: resp.data.createVoucher.uuid,
                  status: 'SUBMITTED'
                },
                selectedVoucherBooking
              )

              if (respStatus.data?.changeVoucherStatus) {
                newVoucher = {
                  ...newVoucher,
                  status: respStatus.data.changeVoucherStatus.status
                }
                delayRefetchBooking(fullBookingQuery, booking?.uuid)
                updateSelectedVoucher({ ...selectedVoucher, ...newVoucher })
              }
            } else {
              newVoucher = {
                ...resp.data.createVoucher
              }
              delayRefetchBooking(fullBookingQuery, booking?.uuid)
              updateSelectedVoucher({ ...selectedVoucher, ...newVoucher })
            }
          }

          handleResponse('Voucher created successfully.', 'success')
        } catch (error) {
          logger.error('Create & submit voucher error', error, createVoucherRequest)
          handleResponse(error, 'error')
        }

        updateVoucherSubmitting(false)
      })
    }

    onHandleUpdate = async () => {
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          message.error('Please fill in all required fields.', 5)

          return
        }

        handleResponse('Updating voucher, hang on...', 'load')

        const { booking, fullBookingQuery, updateVoucher, selectedVoucher, updateSelectedVoucher } =
          this.props

        const updateVoucherRequest = {
          uuid: selectedVoucher.uuid,
          issueDate: values.issueDate?.startOf('day'),
          accountDate: values.accountDate?.startOf('day'),
          vendorUuid: values.vendorUuid,
          customerUuid: values.customerUuid,
          addressUuid: values.addressUuid,
          term: parseInt(values.term),
          paymentType: values.paymentType !== 'CREDIT' ? 'CASH' : 'CREDIT',
          invoiceNumber: values.invoiceNumber,
          salesPersonUuid: values.salesPersonUuid,
          contactPerson: values.contactPerson,
          description: values.description,
          internalDescription: values.internalDescription,
          documentCreatorTemplateUuid: values.documentCreatorTemplateUuid
        }

        try {
          const resp = await updateVoucher(updateVoucherRequest)
          if (resp.data?.updateVoucher) {
            delayRefetchBooking(fullBookingQuery, booking?.uuid)
            updateSelectedVoucher({ ...selectedVoucher, ...resp.data.updateVoucher })
          }
          handleResponse('Voucher updated successfully.', 'success')
        } catch (error) {
          handleResponse(error, 'error')
        }
      })
    }

    onUpdateVoucherItem = async (params) => {
      const {
        fullBookingQuery,
        booking,
        selectedVoucherBooking,
        selectedVoucher,
        updateSelectedVoucher,
        updateVoucherItem
      } = this.props

      if (params) {
        handleResponse('Updating voucher item, hang on...', 'load')

        let updatedVoucher = { ...selectedVoucher }

        try {
          if (selectedVoucher.status !== 'NEW') {
            const omitProps = ['bookingUuid']

            if (!params.jobUuid) {
              omitProps.push('jobUuid')
            }

            if (!params.voucherItemCnUuid) {
              omitProps.push('voucherItemCnUuid')
            }

            const resp = await updateVoucherItem(omit(params, omitProps), selectedVoucherBooking)

            if (fullBookingQuery?.refetch) {
              fullBookingQuery.refetch({
                uuid: booking.uuid
              })
            }

            const reloadedVoucher = await this.getVoucher(selectedVoucher.uuid)
            updatedVoucher = {
              ...updatedVoucher,
              ...reloadedVoucher
            }

            if (resp?.data?.updateVoucherItem) {
              const updatedItem = { ...resp.data.updateVoucherItem }

              updatedVoucher.voucherItems = selectedVoucher.voucherItems.map((item) => {
                if (item.uuid === updatedItem.uuid) {
                  return { ...updatedItem }
                } else {
                  return { ...item }
                }
              })
            }
          } else {
            updatedVoucher.voucherItems = selectedVoucher.voucherItems.map((item) => {
              if (item.uuid === params.uuid) {
                const updatedVoucherItem = {
                  ...item,
                  ...params,
                }

                return calculateVoucherItem(updatedVoucherItem)
              } else {
                return item
              }
            })
          }

          const calculatedVoucher = calculateVoucher(updatedVoucher)

          updateSelectedVoucher(calculatedVoucher)

          handleResponse('Voucher item updated successfully.', 'success')
        } catch (error) {
          logger.error('Update voucher item error', error)
          handleResponse(error, 'error')
        }
      }
    }

    updateVoucherStatus = async (
      status,
      actionMessage = { load: 'Updating', success: 'Updated' }
    ) => {
      const {
        booking,
        fullBookingQuery,
        selectedVoucher,
        changeVoucherStatus,
        updateSelectedVoucher,
        selectedVoucherBooking,
        updateVoucherSubmitting
      } = this.props

      if (status) {
        updateVoucherSubmitting(true)
        handleResponse(`${actionMessage.load} voucher, hang on...`, 'load')

        try {
          await changeVoucherStatus(
            {
              uuid: selectedVoucher.uuid,
              status
            },
            selectedVoucherBooking
          )

          const reloadedVoucher = await this.getVoucher(selectedVoucher.uuid, {
            fetchPolicy: 'network-only'
          })

          delayRefetchBooking(fullBookingQuery, booking?.uuid)

          if (status === 'SUBMITTED') {
            updateSelectedVoucher({
              ...selectedVoucher,
              ...reloadedVoucher,
              status,
              approvals: []
            })
          } else {
            updateSelectedVoucher({
              ...selectedVoucher,
              ...reloadedVoucher,
              status
            })
          }

          handleResponse(`${actionMessage.success} voucher successfully.`, 'success')
          updateVoucherSubmitting(false)
        } catch (error) {
          logger.error('voucher ModalView Container updateVoucherStatus error', error)
          handleResponse(error, 'error')
          updateVoucherSubmitting(false)
        }
      } else {
        console.error('Voucher status required.')
      }
    }

    createPayment = async (values) => {
      const { booking, addPayment, fullBookingQuery, selectedVoucher, updateSelectedVoucher } =
        this.props

      try {
        const addPaymentRequest = {
          reference: values.reference,
          transactionType: values.transactionType,
          remarks: values.remarks,
          date: values.paymentDate,
          voucherPayments: [
            {
              voucherUuid: selectedVoucher.uuid,
              amount: parseFloat(values.amount)
            }
          ]
        }

        await addPayment(addPaymentRequest)

        delayRefetchBooking(fullBookingQuery, booking?.uuid)

        const newSelectedVoucher = await this.getVoucher(selectedVoucher.uuid)

        updateSelectedVoucher(newSelectedVoucher)

        return {
          success: true
        }
      } catch (error) {
        return {
          success: false,
          error
        }
      }
    }

    loadingShift = false;

    handleShiftUp = async (voucherItem) => {
      const { selectedVoucher } = this.props

      const voucherItems = [...selectedVoucher.voucherItems].filter(vi => !vi.isDeleted)
      const currentIndex = voucherItems.findIndex(vi => vi.uuid === voucherItem.uuid)
      const targetIndex = currentIndex - 1

      if (currentIndex <= 0) return

      this.loadingShift = true
      await this.handleUpdateVoucherItems(currentIndex, targetIndex)
      this.loadingShift = false
    }

    handleShiftDown = async (voucherItem) => {
      const { selectedVoucher, updateSelectedVoucher } = this.props

      const voucherItems = [...selectedVoucher.voucherItems].filter(vi => !vi.isDeleted)
      const currentIndex = voucherItems.findIndex((vi) => vi.uuid === voucherItem.uuid)
      const targetIndex = currentIndex + 1

      if (currentIndex === voucherItems.length - 1 || currentIndex === -1)
        return

      this.loadingShift = true
      updateSelectedVoucher({...selectedVoucher})
      await this.handleUpdateVoucherItems(currentIndex, targetIndex)
    }

    handleUpdateVoucherItems = async (currentIndex, targetIndex) => {
      const { updateSelectedVoucher, selectedVoucher } = this.props

      const voucherItems = [...selectedVoucher.voucherItems].filter((vi) => !vi.isDeleted)
      const displayVoucherItems = [...selectedVoucher.voucherItems].filter((vi) => !vi.isDeleted)

      const current = displayVoucherItems[currentIndex]
      const target = displayVoucherItems[targetIndex]

      displayVoucherItems[currentIndex] = { ...current, sorting: targetIndex }
      displayVoucherItems[targetIndex] = { ...target, sorting: currentIndex }

      if (selectedVoucher.status === 'DRAFT') {
        const currentVoucherItem = voucherItems[currentIndex]
        const targetVoucherItem = voucherItems[targetIndex]

        await this.handleSwapVoucherItem(currentVoucherItem, targetVoucherItem)
      }

      const voucher = { ...selectedVoucher, voucherItems: displayVoucherItems }
      this.loadingShift = false
      updateSelectedVoucher(voucher)
    }

    handleSwapVoucherItem = async (currentVoucherItem, targetVoucherItem) => {
      await this.updateVoucherItem(currentVoucherItem, targetVoucherItem.sorting)
      await this.updateVoucherItem(targetVoucherItem, currentVoucherItem.sorting)
    }

    updateVoucherItem = async (voucherItem, sorting) => {
      const { client } = this.props

      try {
        await client.mutate({
          mutation: UPDATE_VOUCHER_ITEM,
          variables: {
            input: {
              uuid: voucherItem.uuid,
              sorting,
              currencyUuid: voucherItem.currency.uuid,
              taxUuid: voucherItem.tax.uuid,
              exchangeRate: voucherItem.exchangeRate,
              baseRate: voucherItem.baseRate,
              localExchangeRate: voucherItem.localExchangeRate,
              quantity: voucherItem.quantity
            }
          }
        })
      } catch (error) {
        console.error('Error updating voucher item', error);
      }
    }

    handleSelectSingleCostItem = async (voucherBooking, costItem) => {
      const {
        booking,
        fullBookingQuery,
        voucherJobs,
        selectedGlobalCompany,
        selectedVoucher,
        updateSelectedVoucher,
        addVoucherItem,
        voucherBookings,
        client
      } = this.props
      const isAp = selectedVoucher.transactionType === VoucherTypeType.Accpay

      voucherBookings?.length &&
        voucherBookings.forEach((booking) => {
          // Remove query here, otherwise will be heavy for big vouchers
          if (!jobsByBookingUuid[booking.uuid] && booking.jobs?.[0]?.jobNo) {
            jobsByBookingUuid[booking.uuid] = booking.jobs
          }
        })

      if (voucherBooking?.uuid && !jobsByBookingUuid[voucherBooking.uuid]) {
        voucherJobs?.refetch({ bookingUuid: voucherBooking.uuid })
      }

      // Wrap in setTimeout to allow time to load jobs
      setTimeout(async () => {
        const jobsForThisBooking = jobsByBookingUuid[voucherBooking?.uuid]
        if (!jobsForThisBooking) {
          jobsByBookingUuid[voucherBooking?.uuid] = this.props.voucherJobs?.jobs?.rows?.filter(
            (j) => j.status === JobStatus.ACTIVE
          )
        }

        let singleJob =
          jobsForThisBooking?.length === 1
            ? jobsForThisBooking?.[0]
            : jobsByBookingUuid[voucherBooking?.uuid]?.length === 1
              ? jobsByBookingUuid[voucherBooking?.uuid]?.[0]
              : undefined

        if (singleJob?.bookingUuid !== voucherBooking?.uuid) {
          singleJob = undefined
        }

        if (selectedVoucher.status === 'NEW') {
          const existingVoucherItems = selectedVoucher.voucherItems || []
          const selectedCompany = selectedVoucher.vendor || selectedVoucher.customer

          if (!selectedCompany) {
            const message = isAp
              ? 'Please select Pay To company first.'
              : 'Please select Bill To company first.'

            handleResponse(message, 'error')
            return
          }

          const exchangeRate = await getExchangeRate({
            isAp,
            client: this.props.client,
            voucher: selectedVoucher,
            costItem,
            costItemOnly: true,
            selectedGlobalCompany
          })

          const localExchangeRate = await getLocalExchangeRate(
            this.props.client,
            selectedVoucher,
            costItem,
            selectedGlobalCompany.company.currency
          )

          const recalibratedItem = await recalibrateVoucherItem(
            costItem,
            isAp,
            voucherBooking?.uuid,
            singleJob,
            selectedCompany,
            exchangeRate?.rate || 1,
            this.props.client,
            selectedVoucher
          )
          recalibratedItem.localExchangeRate = localExchangeRate

          updateSelectedVoucher({
            ...selectedVoucher,
            voucherItems: sortVoucherItems([...existingVoucherItems, recalibratedItem])
          })

          return
        }

        const selectedCompany = selectedVoucher.vendor || selectedVoucher.customer

        const exchangeRate = await getExchangeRate({
          isAp,
          client: this.props.client,
          voucher: selectedVoucher,
          costItem,
          costItemOnly: true,
          selectedGlobalCompany
        })

        const localExchangeRate = await getLocalExchangeRate(
          this.props.client,
          selectedVoucher,
          costItem,
          selectedGlobalCompany.company.currency
        )

        const newVoucherItem = await recalibrateVoucherItem(
          costItem,
          isAp,
          voucherBooking?.uuid,
          singleJob,
          selectedCompany,
          exchangeRate?.rate,
          client,
          selectedVoucher
        )
        newVoucherItem.localExchangeRate = localExchangeRate

        const filteredVoucherItems = selectedVoucher.voucherItems.filter((vi) => !vi.isDeleted)

        try {
          const resp = await addVoucherItem(
            {
              voucherUuid: selectedVoucher.uuid,
              costItemUuid: costItem.uuid,
              currencyUuid: newVoucherItem.currency.uuid || selectedVoucher.currencyUuid,
              baseRate: newVoucherItem.baseRate || 1,
              exchangeRate: newVoucherItem.exchangeRate,
              localExchangeRate: newVoucherItem.localExchangeRate,
              quantity: newVoucherItem.quantity,
              size: newVoucherItem.size,
              taxCode: newVoucherItem.taxCode,
              taxUuid: newVoucherItem.taxUuid,
              description: newVoucherItem.description,
              jobUuid: newVoucherItem.jobUuid,
              sorting: filteredVoucherItems.length
            },
            voucherBooking.uuid
          )

          delayRefetchBooking(fullBookingQuery, booking?.uuid)

          if (resp?.data?.addVoucherItem) {
            const updatedSelectedVoucher = { ...selectedVoucher }
            const updatedVoucherItems = [...updatedSelectedVoucher.voucherItems]

            updatedVoucherItems.push(resp.data.addVoucherItem)
            updatedSelectedVoucher.voucherItems = sortVoucherItems(updatedVoucherItems)
            updatedSelectedVoucher.voucherItems = updatedSelectedVoucher.voucherItems
              .filter(vi => !vi.isDeleted)

            const calculatedVoucher = calculateVoucher(updatedSelectedVoucher)

            updateSelectedVoucher(calculatedVoucher)
          }
        } catch (error) {
          logger.error('Actualize single cost item error', error)
          handleResponse(error, 'error')
        }
      }, 500)
    }

    handleRemoveVoucherItem = async (voucherItem) => {
      const {
        booking,
        selectedVoucher,
        fullBookingQuery,
        updateSelectedVoucher,
        selectedVoucherBooking,
        deleteVoucherItem
      } = this.props

      try {
        if (selectedVoucher.status !== 'NEW') {
          await deleteVoucherItem(
            {
              uuid: voucherItem.uuid || (voucherItem.costItem && voucherItem.costItem.bookingUuid)
            },
            selectedVoucherBooking
          )

          delayRefetchBooking(fullBookingQuery, booking?.uuid)
        }

        const updatedVoucherItems =
          selectedVoucher.voucherItems &&
          selectedVoucher.voucherItems.map((vi) => {
            if (vi.uuid === voucherItem.uuid) {
              return {
                ...vi,
                isDeleted: true
              }
            } else {
              return { ...vi }
            }
          })

        const calculatedVoucher = calculateVoucher({
          ...selectedVoucher,
          voucherItems: updatedVoucherItems
        })

        const sortedVoucherItems = calculatedVoucher.voucherItems.filter(
          (vi) => vi.sorting >= voucherItem.sorting && !vi.isDeleted
        )

        sortedVoucherItems.forEach((vi) => {
          vi.sorting = vi.sorting - 1
        })

        const prevVoucherItems = calculatedVoucher.voucherItems.filter(
          vi => !vi.isDeleted && vi.sorting < voucherItem.sorting
        )


        updateSelectedVoucher({...calculatedVoucher, voucherItems: [...prevVoucherItems, ...sortedVoucherItems]})

        return true
      } catch (error) {
        handleResponse(error, 'error')
        return false
      }
    }

    handleSelectSingleDocument = async (document) => {
      const {
        booking,
        fullBookingQuery,
        selectedVoucher,
        updateSelectedVoucher,
        linkDocumentToVoucher
      } = this.props

      const bookingDocuments = selectedVoucher.bookingDocuments.map((doc) => ({
        ...doc
      }))

      const selected = find(bookingDocuments, (doc) => doc.uuid === document.uuid)

      if (!selected) {
        try {
          if (selectedVoucher.status !== 'NEW') {
            await linkDocumentToVoucher({
              voucherUuid: selectedVoucher.uuid,
              bookingDocumentUuid: document.uuid
            })

            delayRefetchBooking(fullBookingQuery, booking?.uuid)
          }

          const updatedSelectedVoucher = { ...selectedVoucher }
          const updatedBookingDocuments = [...updatedSelectedVoucher.bookingDocuments]
          updatedBookingDocuments.push(document)

          updatedSelectedVoucher.bookingDocuments = updatedBookingDocuments

          updateSelectedVoucher(updatedSelectedVoucher)
        } catch (error) {
          console.error('add booking document error : ')
          console.error(error)
        }
      }
    }

    handleRemoveDocument = async (document) => {
      const {
        booking,
        selectedVoucher,
        fullBookingQuery,
        updateSelectedVoucher,
        unlinkDocumentFromVoucher
      } = this.props

      const updatedBookingDocuments = filter(
        selectedVoucher.bookingDocuments,
        (doc) => doc.uuid !== document.uuid
      )

      try {
        if (selectedVoucher.status !== 'NEW') {
          await unlinkDocumentFromVoucher({
            voucherUuid: selectedVoucher.uuid,
            bookingDocumentUuid: document.uuid
          })

          delayRefetchBooking(fullBookingQuery, booking?.uuid)
        }

        updateSelectedVoucher({
          ...selectedVoucher,
          bookingDocuments: [...updatedBookingDocuments]
        })
      } catch (error) {
        console.error(error)
      }
    }

    getVoucher = async (voucherUuid, options = { fetchPolicy: 'cache-first' }) => {
      const { client, updateSelectedVoucher } = this.props

      try {
        const { data } = await client.query({
          query: voucherQuerySchema,
          fetchPolicy: options.fetchPolicy,
          variables: {
            uuid: voucherUuid
          }
        })

        if (data?.voucher) {
          updateSelectedVoucher({
            ...data.voucher
          })
          return data.voucher
        } else {
          return {}
        }
      } catch (error) {
        logger.error('Voucher Modal voucherQuerySchema error', error)
        handleResponse(error, 'error')
      }
    }

    onCreateCreditNote = async () => {
      try {
        const { booking, fullBookingQuery, createVoucher, selectedVoucher, updateSelectedVoucher } =
          this.props

        handleResponse('Creating credit note, hang on...', 'load')

        const bookingDocumentUuids =
          selectedVoucher.bookingDocuments &&
          selectedVoucher.bookingDocuments.map((doc) => doc.uuid)
        const voucherItems =
          selectedVoucher.voucherItems &&
          selectedVoucher.voucherItems
            .filter((vi) => !vi.isDeleted)
            .map((vi) => {
              return {
                costItemUuid: vi.costItem && vi.costItem.uuid,
                currencyUuid: vi.currency && vi.currency.uuid,
                taxUuid: vi.tax && vi.tax.uuid,
                voucherItemCnUuid: vi.uuid,
                ...pick(vi, [
                  'bookingUuid',
                  'baseRate',
                  'exchangeRate',
                  'localExchangeRate',
                  'unit',
                  'size',
                  'quantity',
                  'description'
                ])
              }
            })

        const createVoucherRequest = {
          uuid: uuidv4(),
          vendorUuid: selectedVoucher?.vendor?.uuid,
          customerUuid: selectedVoucher?.customer?.uuid,
          currencyUuid: selectedVoucher?.currency?.uuid,
          addressUuid: selectedVoucher?.address?.uuid,
          isCreditNote: true,
          voucherItems,
          bookingDocumentUuids: bookingDocumentUuids || [],
          overrideIncompleteDocs: true,
          ...pick(selectedVoucher, [
            'type',
            'term',
            'invoiceNumber',
            'issueDate',
            'accountDate',
            'paymentType',
            'description',
            'contactPerson',
            'internalDescription'
          ])
        }

        const resp = await createVoucher(createVoucherRequest)

        if (resp.data?.createVoucher) {
          delayRefetchBooking(fullBookingQuery, booking?.uuid)

          updateSelectedVoucher(resp.data.createVoucher)

          handleResponse('Credit note created successfully.', 'success')
        } else {
          handleResponse('Something went wrong.', 'error')
        }
      } catch (error) {
        handleResponse(error, 'error')
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          handleSubmit={this.onHandleSubmit}
          handleUpdate={this.onHandleUpdate}
          createPayment={this.createPayment}
          approveVoucher={this.approveVoucher}
          loadRequiredData={this.loadRequiredData}
          onRemoveDocument={this.handleRemoveDocument}
          updateVoucherStatus={this.updateVoucherStatus}
          onRemoveVoucherItem={this.handleRemoveVoucherItem}
          loadingShift={this.loadingShift}
          handleShiftUp={this.handleShiftUp}
          handleShiftDown={this.handleShiftDown}
          handleCreateCreditNote={this.onCreateCreditNote}
          onSelectSingleDocument={this.handleSelectSingleDocument}
          onSelectSingleCostItem={this.handleSelectSingleCostItem}
          handleUpdateVoucherItem={this.onUpdateVoucherItem}
        />
      )
    }
  }

  return compose(
    UserQuery,
    fullBookingQuery,
    voucherJobs,
    withApollo,
    withBooking,
    addPaymentMutation,
    createVoucherMutation,
    updateVoucherMutation,
    addVoucherItemWithRefetchBooking,
    deleteVoucherItemWithRefetchBooking,
    updateVoucherItemMutation,
    linkDocumentToVoucherMutation,
    approveVoucherWithRefetchBooking,
    unlinkDocumentFromVoucherMutation,
    changeVoucherStatusWithBookingRefetch,
    Form.create(),
    connect(
      (state) => ({
        loading: state.voucher.loading,
        visible: state.voucher.showVoucher,
        submitting: state.voucher.submitting,
        selectedVoucher: state.voucher.selectedVoucher,
        voucherBookings: state.voucher.voucherBookings,
        costsheetBookings: state.voucher.costsheetBookings,
        selectedVoucherBooking: state.voucher.selectedVoucherBooking,
        selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
      }),
      (dispatch) => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions,
            ...currencyActions
          },
          dispatch
        )
      })
    )
  )(WithVoucher)
}
