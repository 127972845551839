import { useEffect } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import find from 'lodash/find'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import findIndex from 'lodash/findIndex'
import { Alert, Skeleton } from 'antd'
import { gql, useLazyQuery } from '@apollo/client'

import * as voucherActions from 'App/states/reducers/voucher'
import { calculateVoucherItem } from '@shipx/formula2'
import { calculateUnbilled, calculateGrossProfit, recalibrateVoucherItem } from 'App/utils/voucher'
import { getExchangeRates, getLocalExchangeRate, calculateExchangeRate } from '../Utils'
import { VoucherTypeType } from 'App/types/graphql'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'

const jobsByBookingUuid = {}

const getError = (errors = [], field) => {
  return find(errors, (e) => e.field === field)
}

const JOBS_FOR_SELECTOR_QUERY1 = gql`
  query bulkActualizeCostItemJobs($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        bookingUuid
        details
        jobNo
        no
        status
        uuid
      }
    }
  }
`

export default (WrappedComponent) => {
  const WithCostItems = (props) => {
    const {
      client,
      voucherBookings,
      costsheetBookings,
      selectedVoucher,
      updateSelectedVoucher,
      selectedGlobalCompany
    } = props

    const [getJobs, { loading: jobsLoading, error: jobsError, data: jobs }] = useLazyQuery(
      JOBS_FOR_SELECTOR_QUERY1,
      {
        client,
        fetchPolicy: 'cache-and-network'
      }
    )

    voucherBookings?.length &&
      voucherBookings.forEach((booking) => {
        if (booking?.uuid && !jobsByBookingUuid[booking?.uuid]) {
          jobsByBookingUuid[booking?.uuid] = booking?.jobs
        }
      })

    useEffect(() => {
      costsheetBookings?.length &&
        costsheetBookings.forEach((booking) => {
          if (!voucherBookings?.find((bk) => bk.uuid === booking?.uuid)) {
            getJobs({ variables: { bookingUuid: booking?.uuid } })
          }
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      costsheetBookings?.length &&
        costsheetBookings.forEach((booking) => {
          jobsByBookingUuid[booking?.uuid] = jobs?.jobs?.rows
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobs])

    if (jobsLoading || !selectedGlobalCompany?.company) {
      return <Skeleton active />
    }

    if (jobsError) {
      logger.error('JOBS_FOR_SELECTOR_QUERY1 for bulkActualizeCostItemsButton error', jobsError)
      handleResponse(jobsError, 'error')
      return <Alert message="Error loading jobs." type="error" />
    }

    const handleSelectAll = async (selected, selectedRows, changeRows) => {
      // Populate jobs in cache to later be included in actualized cost items
      changeRows.forEach((row) => {
        const voucherBooking = find(costsheetBookings, (b) => b.uuid === row.details.bookingUuid)
        if (voucherBooking?.uuid && !jobsByBookingUuid[voucherBooking?.uuid]) {
          getJobs({ variables: { bookingUuid: voucherBooking?.uuid } })
          jobsByBookingUuid[voucherBooking?.uuid] = jobs?.jobs?.rows
        }
      })

      // Wrap in setTimeout to allow time to load jobs
      setTimeout(async () => {
        try {
          const transactionType = selectedVoucher.transactionType
          const isAp = transactionType === VoucherTypeType.Accpay
          const existingVoucherItems = selectedVoucher.voucherItems || []
          let updatedVoucherItems = []

          if (selected) {
            const groupedVoucherItems = groupBy(changeRows, (ci) => {
              if (isAp) {
                return ci.details?.costCurrency?.uuid
              } else {
                return ci.details?.sellCurrency?.uuid
              }
            })

            const exchangeRates = await getExchangeRates({
              client: props.client,
              voucher: selectedVoucher,
              groupedVoucherItems
            })

            updatedVoucherItems = await Promise.all(
              changeRows.map(async (row) => {
                const selectedCompany = selectedVoucher.vendor || selectedVoucher.customer
                const voucherBooking = find(
                  costsheetBookings,
                  (b) => b.uuid === row.details.bookingUuid
                )
                const voucherCostItem = find(
                  voucherBooking.costItems,
                  (ci) => ci.uuid === row.details.uuid
                )

                const localExchangeRate = await getLocalExchangeRate(
                  props.client,
                  selectedVoucher,
                  voucherCostItem,
                  selectedGlobalCompany.company.currency
                )

                const currentBooking = jobsByBookingUuid[voucherBooking?.uuid]
                const singleJob = (currentBooking?.length === 1 && currentBooking[0]) || null

                const recalibratedItem = await recalibrateVoucherItem(
                  row.details,
                  isAp,
                  voucherBooking?.uuid,
                  singleJob,
                  selectedCompany,
                  null,
                  props.client,
                  selectedVoucher
                )
                recalibratedItem.localExchangeRate = localExchangeRate

                const overrideExchangeRate = calculateExchangeRate({
                  isAp,
                  voucher: selectedVoucher,
                  booking: voucherBooking,
                  costItem: voucherCostItem,
                  voucherItem: recalibratedItem,
                  exchangeRates,
                  selectedGlobalCompany
                })

                return calculateVoucherItem({
                  ...recalibratedItem,
                  exchangeRate: (overrideExchangeRate && overrideExchangeRate.rate) || 1
                })
              })
            )

            updateSelectedVoucher({
              ...selectedVoucher,
              voucherItems: [...existingVoucherItems, ...updatedVoucherItems]
            })
          } else {
            updatedVoucherItems = filter(
              existingVoucherItems,
              (existItem) =>
                findIndex(changeRows, (row) => existItem.uuid === row.details.uuid) === -1
            )

            updateSelectedVoucher({
              ...selectedVoucher,
              voucherItems: [...updatedVoucherItems]
            })
          }
        } catch (error) {
          // When bulk search bookings, voucherBooking will be undefined for other bookings because there's only 1 in costsheetBookings
          // and hence cannot find costItems of undefined
        }
      }, 500)
    }

    return (
      <WrappedComponent
        {...props}
        costItems={props.costItems}
        getError={getError}
        handleSelectAll={handleSelectAll}
        calculateUnbilled={calculateUnbilled}
        calculateGrossProfit={calculateGrossProfit}
      />
    )
  }

  return compose(
    withApollo,
    connect(
      (state) => ({
        voucherBookings: state.voucher.voucherBookings,
        costItemsView: state.voucher.costItemsView,
        selectedVoucher: state.voucher.selectedVoucher,
        costsheetBookings: state.voucher.costsheetBookings,
        showDeletedCostItems: state.voucher.showDeletedCostItems,
        selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
      }),
      (dispatch) => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithCostItems)
}
