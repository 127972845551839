import { gql, useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Col, Form, Row, Spin } from 'antd'
import IncentiveDetails from 'App/components/Transport/Components/Modals/JobActivityModal/IncentiveDetails'
import useJobTypes from 'App/hooks/useJobTypes'
import { Job, type TransportJob } from 'App/types/graphql'
import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import JobFormDetails from './JobFormDetails'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

import { ActionItem, Actions } from 'App/components/Manage/Styled'

import { UPDATE_JOB, UPDATE_JOB_TRIP } from 'App/graphql/booking'

import type { Incentive, UpdateJobInput } from 'App/types/graphql'

const QUERY_JOB = gql`
  query jobContainerSeal($uuid: UUID!) {
    job(uuid: $uuid) {
      type
      uuid
      no
      details
      trips {
        uuid
        details
      }
    }
  }
`

interface LegFormDisplayProps {
  client: any
  form: any
  value: any
  legsLoading: boolean
  incentives: Incentive[]
  incentiveLoading: boolean
  leg?: TransportJob
}

const JobContainerSealDisplay = memo((props: LegFormDisplayProps) => {
  const { client, form, leg, legsLoading, incentives, incentiveLoading } = props
  const { requestJobTypes, jobTypes } = useJobTypes(client)
  const [updateJobMain, { loading: loadingJob }] = useMutation(UPDATE_JOB, { client })
  const [updateJobTripMutation] = useMutation(UPDATE_JOB_TRIP, { client })
  const { loading, data: jobResults, error, refetch } = useQuery(QUERY_JOB, {
    skip: !leg?.jobUuid,
    client,
    variables: { uuid: leg?.jobUuid },
    fetchPolicy: 'cache-first'
  })

  const [editJobMode, setEditJobMode] = useState(true)
  const [jobFormSuccessMsg, setJobFormSuccessMsg] = useState('')
  const [jobFormError, setJobFormError] = useState('')
  const [job, setJob] = useState<Job>({})
  const [locLeg, setLocLeg] = useState<any>(leg)
  const [dynamicFields, setDynamicFields] = useState<any>()
  const [tripDynamicFields, setTripDynamicFields] = useState<any>()

  useEffect(() => {
    setDynamicFields(jobTypes[0]?.dynamicFields?.map((i) => i?.key) || [])
    setTripDynamicFields(jobTypes[0]?.tripDynamicFields?.map((i) => i?.key) || [])
  }, [jobTypes])

  useEffect(() => {
    if (jobResults?.job) {
      setJob(jobResults.job)
    }
  }, [jobResults])

  const onSwitchEditJobMode = useCallback(() => {
    setEditJobMode((state: boolean) => !state)
    setJobFormSuccessMsg('')
    setJobFormError('')
  }, [])

  const onUpdateJob = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      const jobDetails = {}
      const tripDetails = {}
      dynamicFields?.forEach(key => {
        if (key in values.job.details) {
          jobDetails[key] = values?.job.details[key];
        }
      });

      tripDynamicFields?.forEach(key => {
        if (key in values.trip.details) {
          tripDetails[key] = values?.trip.details[key];
        }
      });
      try {
        setJobFormSuccessMsg('')
        setJobFormError('')

        const updateObj: UpdateJobInput = {
          uuid: leg?.jobUuid,
          details: jobDetails
        }
        setJobFormSuccessMsg('Saving job details...')

        const res = await updateJobMain({ variables: { input: updateObj } })
        await updateJobTripMutation({
          variables: {
            input: {
              jobUuid: leg?.jobUuid,
              type: jobTypes[0]?.code,
              trips: [{ details: tripDetails, uuid: leg?.tripUuid }]
            }
          }
        })

        if (res?.data?.updateJob?.uuid) {
          refetch()
          onSwitchEditJobMode()

          respHandler('Successfully updated job.', 'success')
        } else {
          setJobFormSuccessMsg('')
          respHandler('No job selected for update.', 'warning')
        }

        setLocLeg({ ...locLeg, tripSeal: values?.trip?.seal })
      } catch (error: any) {
        respHandler(error, 'error')
        logger.error('UpdateLegForm updateJobMain error', error)
        setJobFormSuccessMsg('')
        setJobFormError(error.message || JSON.stringify(error))
      }
    })
  }, [form, leg, updateJobMain])

  useEffect(() => {
    if (!job.type) return
    requestJobTypes([job.type])

  }, [job.type])

  if (loading || loadingJob) {
    return <Spin />
  }

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Col xs={24} xl={24} span={8}>
      <JobFormDetails job={job} leg={locLeg} editMode={editJobMode} value={props.value} form={form} client={client} />

      <Row gutter={24}>
        <Col span={5} />
        <Col span={19}>
          {jobFormSuccessMsg && (
            <Alert message={jobFormSuccessMsg} type="success" style={{ fontSize: '12px' }} />
          )}
          {jobFormError && (
            <Alert message={jobFormError} type="error" style={{ fontSize: '12px' }} />
          )}
        </Col>
      </Row>

      <Actions>
        <ActionItem span={24}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              icon={editJobMode ? '' : 'edit'}
              loading={legsLoading}
              onClick={onSwitchEditJobMode}
            >
              {editJobMode ? 'Cancel' : 'Edit'}
            </Button>
            {editJobMode && (
              <Button icon="check" loading={loadingJob} onClick={onUpdateJob}>
                Submit
              </Button>
            )}
          </Row>
        </ActionItem>
      </Actions>
      <IncentiveDetails incentives={incentives} incentiveLoading={incentiveLoading} />
    </Col>
  )
})

// @ts-ignore
export default withApollo(Form.create()(JobContainerSealDisplay))
