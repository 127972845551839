import { memo, useState, useCallback } from 'react'
import { Button, Col, DatePicker, Form, Row, notification } from 'antd'
import { useMutation } from '@apollo/client'
import { withApollo } from 'react-apollo'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

import CustomTimeInput from 'App/components/Shared/CustomTimeInput'
import { DisplayOnlyLabel, TimeForm } from 'App/components/Voucher/Styled'
import { formatDateAndTime } from 'App/components/Transport/Components/Shared/computeValidDateRange'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { isActiveLeg } from 'App/components/Transport/Utils/jobHelper'

import { UPDATE_LEG_TIMING_MUTATION } from 'App/components/Transport/Schemas/schema'

import type { TransportJob } from 'App/types/graphql'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

const checkPrevLegEnded = (legs: Array<TransportJob>, leg: TransportJob) => {
  const currentLeg: TransportJob | undefined = legs?.find(
    (l: TransportJob) => l.legUuid === leg?.legUuid
  )
  // @ts-ignore
  const prevLeg: TransportJob | undefined = legs?.find(
    //@ts-ignore
    (l: TransportJob) => l.sequence === currentLeg?.sequence - 1
  )

  // Dont change this logic. it will break time update.
  if (prevLeg && !prevLeg.endOut && currentLeg?.planStart) {
    return { error: 'The previous leg has not ended.' }
  }
}

const checkDriverVehicle = (leg: TransportJob) => {
  if (leg.planStart && !leg.driverUuid && !leg.driverName && !leg.driverEntity?.uuid) {
    return { error: 'Please select a driver.' }
  }

  if (leg.planStart && !leg.vehicleUuid && !leg.vehicleName && !leg.vehicleEntity?.uuid) {
    return { error: 'Please select a vehicle.' }
  }
}

const setLegTiming = (currentActivity: any, setLegActivities: any, timing: any) => {
  setLegActivities((activities: any) => {
    const newActs = activities.map((act: any) => {
      if (act.label === currentActivity?.label) {
        act.timing = timing || null
      }
      return act
    })
    return newActs
  })
}

const checkNextLegStarted = async (
  legs: Array<TransportJob>,
  leg: TransportJob,
  formattedDateTime: Date
) => {
  const currentLeg: TransportJob | undefined = await legs?.find(
    (l: TransportJob) => l.legUuid === leg?.legUuid
  )
  // @ts-ignore
  const nextLeg: TransportJob | undefined = await legs?.find(
    //@ts-ignore
    (l: TransportJob) => l.sequence === currentLeg?.sequence + 1
  )

  if (nextLeg?.start) {
    if (!formattedDateTime) {
      return 'This time cannot be empty because the next leg already started.'
    }

    const afterNextLegPlnStart =
      formattedDateTime?.getTime() / 1000 - new Date(nextLeg?.start)?.getTime() / 1000 >= 60
    if (afterNextLegPlnStart) {
      return "This time cannot be after the next leg's start time."
    }
  }

  return null
}

const validateTimings = async (
  activities: any,
  legs: Array<TransportJob>,
  leg: TransportJob,
  formattedDateTime: Date
) => {
  if (!activities.length) return

  const nextLegErr = await checkNextLegStarted(legs, leg, formattedDateTime)
  if (nextLegErr) {
    return { error: nextLegErr }
  }

  const planStartTime = activities?.find((act: any) => act.label === 'planStart').timing
  const planEndTime = activities?.find((act: any) => act.label === 'planEnd').timing
  const planStartAheadEnd =
    new Date(planStartTime)?.getTime() / 1000 - new Date(planEndTime)?.getTime() / 1000 > 60
  if (planStartAheadEnd && planEndTime) {
    return { error: 'Plan Start time should not be after Plan End time.' }
  }

  const noStartOut = activities?.find((act: any) => act.label === 'startOut' && !act.timing)
  const hasEnd = activities?.find((act: any) => act.label === 'end' && act.timing)
  if (noStartOut && hasEnd) {
    return { error: 'There is End time, but Start Out time is not entered.' }
  }

  let prevPrevTime: number = 0
  let prevTime: number = 0
  let prevActivity: any = null
  for (let i = 0; i < activities.length; i++) {
    if (activities[i]?.timing) {
      const currentTime = new Date(activities[i].timing)?.getTime() / 1000
      if (!prevTime) {
        prevTime = currentTime
      }

      const isOrPrevPlan =
        activities[i]?.label.includes('plan') || prevActivity?.label.includes('plan')
      if (!isOrPrevPlan && !prevActivity?.timing) {
        return {
          error: `There is ${activities[i].text} time, but ${prevActivity?.text} time is not entered.`
        }
      }

      const prevPrevAheadCurrent = prevPrevTime - currentTime > 60
      const prevAheadCurrent = prevTime - currentTime > 60
      const prevIsPlan = prevActivity?.label.includes('plan')
      const isPlan = activities[i]?.label.includes('plan')
      const inTheFuture = currentTime - new Date().getTime() / 1000 > 60

      if (prevPrevAheadCurrent && prevIsPlan) {
        return {
          error: `${activities[i - 2].text} time should not be after ${activities[i].text} time.`
        }
      }
      if (prevAheadCurrent && !prevIsPlan) {
        return {
          error: `${activities[i - 1].text} time should not be after ${activities[i].text} time.`
        }
      }
      if (!isPlan && inTheFuture) {
        return { error: `${activities[i].text} time should not be in the future.` }
      }

      if (i > 1) {
        prevPrevTime = prevTime
      }
      prevTime = currentTime
    }

    prevActivity = activities[i]
  }
}

const TimeInputWrapper = memo((props: any) => {
  const {
    form,
    client,
    currentActivity,
    leg,
    legs,
    legActivities,
    legsLoading,
    refetchLegs,
    setLegActivities,
    setDateSuccessMsg,
    setDateError,
    setRenderCount,
    isControlMode,
    setActivityObj,
  } = props
  const { getFieldDecorator } = form

  const [timeInput, setTimeInput] = useState('')

  const [updateLegTiming, { loading: updateLoading }] = useMutation(UPDATE_LEG_TIMING_MUTATION, {
    client
  })

  const onSetEditMode = useCallback(
    (thisActivity: any) => {
      setLegActivities((activities: any) => {
        const newActs = activities.map((act: any) => {
          if (act.label === thisActivity.label) {
            act.isEditable = !act.isEditable
          } else {
            act.isEditable = false
          }
          return act
        })
        return newActs
      })

      setRenderCount((count: number) => count + 1)
    },
    [setLegActivities, setRenderCount]
  )

  const onEndEditMode = useCallback(
    (thisActivity: any) => {
      setLegActivities((activities: any) => {
        const newActs = activities.map((act: any) => {
          if (act.label === thisActivity.label) {
            act.isEditable = false
          } else {
            act.isEditable = false
          }
          return act
        })
        return newActs
      })

      setRenderCount((count: number) => count + 1)
    },
    [setLegActivities, setRenderCount]
  )

  const onSubmitControlMode = useCallback(async () => {
    const values = await form.getFieldsValue()
    const dateInput =
      values?.[`${currentActivity?.label}-date`] ||
      form.getFieldValue(`${currentActivity?.label}-date`) || leg[currentActivity?.label] || moment()
    const timeInput =
      values?.[`${currentActivity?.label}-time`] ||
      form.getFieldValue(`${currentActivity?.label}-time`) ||
      moment().format('HHmm')

    let formattedDateTime: any = null
    if (dateInput && timeInput) {
      formattedDateTime = formatDateAndTime(dateInput, timeInput)
    }
    setActivityObj((prev) => {
      return {
        ...prev,
        [currentActivity.label]: formattedDateTime
      }
    })
    onEndEditMode(currentActivity)
    setLegTiming(currentActivity, setLegActivities, formattedDateTime)
  }, [currentActivity])

  const onSubmitTime = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      const isDeleted: boolean = !isActiveLeg(leg)
      if (isDeleted) {
        return respHandler(
          `Leg ${leg?.sequence} is ${leg?.legStatus} and cannot be updated.`,
          'warning'
        )
      }

      try {
        setDateSuccessMsg('')
        setDateError('')

        const driverVehicle: any = checkDriverVehicle(leg)
        const prevLeg: any = checkPrevLegEnded(legs, leg)
        if (driverVehicle?.error || prevLeg?.error) {
          setLegTiming(currentActivity, setLegActivities, leg[currentActivity.label])
          onEndEditMode(currentActivity)
          setDateSuccessMsg('')
          return setDateError(driverVehicle?.error || prevLeg?.error)
        }

        const dateInput =
          values?.[`${currentActivity?.label}-date`] ||
          form.getFieldValue(`${currentActivity?.label}-date`) ||
          leg[currentActivity.label] ||
          moment()
        const timeInput =
          values?.[`${currentActivity?.label}-time`] ||
          form.getFieldValue(`${currentActivity?.label}-time`)

        let formattedDateTime: any = null

        if (dateInput && timeInput) {
          formattedDateTime = formatDateAndTime(dateInput, timeInput)
        }
        setLegTiming(currentActivity, setLegActivities, formattedDateTime)

        const validation: any = await validateTimings(legActivities, legs, leg, formattedDateTime) // await to make sure legActivities state has updated
        if (validation?.error) {
          setLegTiming(currentActivity, setLegActivities, leg?.[currentActivity?.label] || null)
          onEndEditMode(currentActivity)
          setDateSuccessMsg('')
          return setDateError(validation?.error)
        }

        const updateObj: any = {
          _id: uuidv4(),
          tripUuid: leg?.tripUuid,
          legUuid: leg?.legUuid,
        }
        updateObj[currentActivity?.label] = formattedDateTime
        setDateSuccessMsg(`Saving ${currentActivity.text} time...`)
        onEndEditMode(currentActivity)

        if (updateObj.legUuid && updateLegTiming) {
          const res = await updateLegTiming({ variables: { input: updateObj } })

          if (res?.data?.updateLegTiming?.success) {
            notification.success({
              message: `Successfully updated ${currentActivity.text} time.`
            })
            setDateSuccessMsg('')
            setDateError('')
            await refetchLegs()
          }
        }
      } catch (error) {
        respHandler(error, 'error')
        logger.error(`TimeInputWrapper onSubmitTime ${currentActivity.text} error`, error)
        onEndEditMode(currentActivity)
        setDateSuccessMsg('')
        // @ts-ignore
        setDateError(error?.toString())
      }
    })
  }, [
    currentActivity,
    form,
    leg,
    legActivities,
    legs,
    onEndEditMode,
    refetchLegs,
    setDateError,
    setDateSuccessMsg,
    setLegActivities,
    updateLegTiming
  ])

  return (
    <>
      {currentActivity.isEditable ? (
        <Row gutter={24} key={currentActivity.code} style={{ width: '400px' }}>
          <Form.Item label={currentActivity.text} {...formItemLayout}>
            <Col span={11}>
              <Form.Item style={{ display: 'inline-block' }}>
                {getFieldDecorator(`${currentActivity.label}-date`, {
                  initialValue:
                    (currentActivity.timing && moment(currentActivity.timing)) || moment()
                })(<DatePicker style={{ width: '130px' }} placeholder="Select a date..." />)}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item style={{ display: 'inline-block' }}>
                {getFieldDecorator(`${currentActivity.label}-time`, {
                  initialValue:
                    (currentActivity.timing && moment(currentActivity.timing).format('HHmm')) ||
                    moment().format('HHmm')
                })(
                  <CustomTimeInput
                    allowClear
                    autoFocus
                    highlightText
                    onSubmitTime={isControlMode ? onSubmitControlMode : onSubmitTime}
                    timeInput={timeInput}
                    setTimeInput={setTimeInput}
                    style={{ width: '80px' }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                id={`submit-time-button-${currentActivity.label}`}
                icon="check"
                loading={updateLoading || legsLoading}
                type="link"
                style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
                onClick={isControlMode ? onSubmitControlMode : onSubmitTime}
              />
            </Col>
          </Form.Item>
        </Row>
      ) : (
        <Row gutter={24} key={currentActivity.code} style={{ width: '400px' }}>
          <TimeForm>
            <Form.Item label={currentActivity.text} {...formItemLayout}>
              <Col span={11}>
                <Form.Item style={{ display: 'inline-block' }}>
                  {getFieldDecorator(`${currentActivity.label}-date`, {
                    initialValue: currentActivity.timing && moment(currentActivity.timing)
                  })(
                    /* @ts-ignore */
                    <DisplayOnlyLabel onClick={() => onSetEditMode(currentActivity)}>
                      {(currentActivity.timing &&
                        moment(currentActivity.timing).format('YYYY-MM-DD')) ||
                        '-'}
                    </DisplayOnlyLabel>
                  )}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item style={{ display: 'inline-block' }}>
                  {getFieldDecorator(`${currentActivity.label}-date`, {
                    initialValue: currentActivity.timing && moment(currentActivity.timing)
                  })(
                    /* @ts-ignore */
                    <DisplayOnlyLabel onClick={() => onSetEditMode(currentActivity)}>
                      {(currentActivity.timing && moment(currentActivity.timing).format('HHmm')) ||
                        '-'}
                    </DisplayOnlyLabel>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button
                  id={`edit-time-button-${currentActivity.label}`}
                  icon="edit"
                  type="link"
                  style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
                  onClick={() => onSetEditMode(currentActivity)}
                />
              </Col>
            </Form.Item>
          </TimeForm>
        </Row>
      )}
    </>
  )
})

// @ts-ignore
export default withApollo(Form.create()(TimeInputWrapper))
