import { useLazyQuery } from '@apollo/client'
import FormItemMapper from 'App/components/Manage/FormItemMapper'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import DynamicFieldComponent, { DATE_FORMAT } from 'App/components/Shared/DynamicFieldComponent'
import { JOB_TYPE_QUERY } from 'App/graphql/booking'

import { DynamicField, DynamicFieldControl, DynamicFieldType } from 'App/types/graphql'
import type { Job, JobType, TransportJob } from 'App/types/graphql'
import { startCase } from 'lodash'
import moment from 'moment'
import DisplayBookingDetails from './DisplayBookingDetails'
import responseHandler from 'App/utils/responseHandler'

const leftFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

interface JobFormDetailsProps {
  form: any
  value: any
  job: Job
  leg: TransportJob
  client: any
  editMode: boolean
}

const JobFormDetails = memo((props: JobFormDetailsProps) => {
  const { form, job, leg, client, editMode = false, value = {} } = props
  const { getFieldDecorator } = form
  const [jobType, setJobType] = useState<JobType>()

  const generateFormItemProps = useCallback(
    (field: DynamicField, details: Record<string, any>, type: 'job' | 'trip') => {
      const key = field.key || ''
      const val = details?.[key]

      return {
        label: startCase(key),
        value: `${type}.details.${key}`,
        input: <DynamicFieldComponent id={key} field={field} disabled={!editMode} />,
        attributes: {
          initialValue:
            field.type === DynamicFieldType.Date ? (val ? moment(val, DATE_FORMAT) : null) : val,
          rules: [
            {
              required: DynamicFieldControl.Required === field?.control,
              message: `${field.key} is required`
            }
          ]
        },
        ...leftFormItemLayout
      }
    },
    [editMode, value]
  )

  const cols = useMemo(() => {
    if (job && !job.details) job.details = {}
    const jobFields = (jobType?.dynamicFields || []).map((field: DynamicField | null) => {
      if (field) {
        return generateFormItemProps(field, job?.details, 'job')
      }
      return null
    })

    const trip = job.trips?.find((trip) => trip?.uuid === leg.tripUuid)

    if (trip && !trip.details) trip.details = {}
    const tripFields = (jobType?.tripDynamicFields || []).map((field: DynamicField | null) => {
      if (field) {
        return generateFormItemProps(field, trip?.details, 'trip')
      }
      return null
    })

    return {
      jobFields,
      tripFields
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    jobType?.dynamicFields,
    jobType?.tripDynamicFields,
    job.details,
    job.trips,
    editMode,
    leg.tripUuid,
    value
  ])

  const [getJobType] = useLazyQuery(JOB_TYPE_QUERY, {
    client,
    onCompleted: (data) => {
      const jobType: JobType = data?.jobType

      if (!jobType) return

      setJobType(jobType)
    },
    onError: (error) => {
      responseHandler(error, 'error')
    }
  })

  useEffect(() => {
    if (!job.type || jobType?.code === job.type) return

    getJobType({
      variables: {
        code: job.type
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.type, jobType])

  return (
    <>
      <DisplayBookingDetails leg={leg} headerTitle={'Booking Details'} />
      <FormItemMapper
        fields={[{ cols: cols.jobFields }]}
        headerTitle={'Job Details'}
        getFieldDecorator={getFieldDecorator}
      />
      <FormItemMapper
        fields={[{ cols: cols.tripFields }]}
        headerTitle={'Trip Details'}
        getFieldDecorator={getFieldDecorator}
      />
    </>
  )
})

export default JobFormDetails
