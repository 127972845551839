import { gql } from 'App/types'

export const UPDATE_GENERATED_DOCUMENT = gql(`
  mutation updateGeneratedDocument($uuid: UUID!, $input: UpdateGeneratedDocumentInput!) {
    updateGeneratedDocument(uuid: $uuid, input: $input) {
      uuid
      groups
      number
      data
      dataMeta
      template
      status
      createdAt
      updatedAt
    }
  }
`)

export const GENERATED_DOCUMENTS_QUERY = gql(`
  query generatedDocuments($groups: [String], $limit: Int, $offset: Int) {
    generatedDocuments(groups: $groups, limit: $limit, offset: $offset) {
      rows {
        uuid
        documentTemplate {
          code
        }
        number
        status
        createdAt
        updatedAt
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const GENERATED_DOCUMENT_QUERY = gql(`
  query generatedDocument($uuid: UUID!) {
    generatedDocument(uuid: $uuid) {
      uuid
      documentTemplate {
        fields {
          key
          label
          type
          customValuesArray
          control
          autoPopulateHandler
          isAutoPopulate
          multiSelect
          query
          enumName
        }
        fieldsObject
      }
      groups
      number
      data
      dataMeta
      template
      status
    }
  }
`)

export const UPDATE_GENERATED_DOCUMENT_STATUS = gql(`
  mutation updateGeneratedDocumentStatus($uuid: UUID!, $status: GeneratedDocumentStatus!) {
    updateGeneratedDocumentStatus(uuid: $uuid, status: $status) {
      uuid
    }
  }
`)

export const SUBMIT_GENERATED_DOCUMENT = gql(`
  mutation submitGeneratedDocument($uuid: UUID!) {
    submitGeneratedDocument(uuid: $uuid) {
      uuid
    }
  }
`)

export const POPULATE_GENERATED_DOCUMENT = gql(`
  mutation populateGeneratedDocument($uuid: UUID!, $type: PopulateType) {
    populateGeneratedDocument(uuid: $uuid, type: $type) {
      uuid
    }
  }
`)

export const DOCUMENT_GENERATOR_TEMPLATES_QUERY = gql(`
  query documentGeneratorTemplates($limit: Int, $offset: Int) {
    documentGeneratorTemplates(limit: $limit, offset: $offset) {
      rows {
        uuid
        code
        template
        status
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const GENERATE_DOCUMENT = gql(`
  mutation generateDocument($input: GenerateDocumentInput!) {
    generateDocument(input: $input) {
      uuid
      number
      status
      createdAt
      updatedAt
    }
  }
`)
