import { gql } from '@apollo/client'

const bookingDocumentProps = /* GraphQL */ `
  fragment BookingDocumentProps on BookingDocument {
    uuid
    jobUuid
    tripUuid
    type
    reference
    remarks
    tags
    status
    url
    document {
      uuid
      name
      type
      size
    }
    approvals {
      uuid
      type
      status
      createdAt
      approvedBy {
        email
      }
    }
    createdBy {
      uuid
      email
    }
    verifiedBy {
      uuid
      email
    }
    createdAt
    updatedAt
  }
`

export const VERIFY_BOOKING_DOCUMENT = gql`
  mutation verifyBookingDocument($uuid: UUID!) {
    verifyBookingDocument(uuid: $uuid) {
      ...BookingDocumentProps

      ${bookingDocumentProps}
    }
  }
`

export const APPROVE_BOOKING_DOCUMENT = gql`
  mutation approveBookingDocument1 ($uuid: UUID!, $type: String!) {
    approveBookingDocument(uuid: $uuid, type: $type) {
      ...BookingDocumentProps

      ${bookingDocumentProps}
    }
  }
`

export const UPDATE_BOOKING_DOCUMENT = gql`
  mutation updateBookingDocument(
    $uuid: UUID!,
    $type: String!,
    $remarks: String,
    $reference: String,
    $tags: [String]
    $jobUuid: UUID,
    $tripUuid: UUID
    ) {
    updateBookingDocument(
      uuid: $uuid,
      type: $type,
      remarks: $remarks,
      reference: $reference,
      tags: $tags,
      jobUuid: $jobUuid
      tripUuid: $tripUuid
      ) {
        ...BookingDocumentProps

      ${bookingDocumentProps}
    }
  }
`

export const DELETE_BOOKING_DOCUMENT = gql`
  mutation deleteBookingDocument($uuid: UUID!) {
    deleteBookingDocument(uuid: $uuid) {
      ...BookingDocumentProps

      ${bookingDocumentProps}
    }
  }
`
