import {
  DEPARTMENT_QUERY,
  GET_COMPANIES_QUERY,
  QUOTATION_QUERY
} from 'App/components/Booking/DynamicField/schema'
import { getChronologyDate } from 'App/components/Booking/NewBookingForm/SubmitAction/convertValuesToInput'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import {
  Booking,
  DynamicField,
  DynamicFieldQuery,
  DynamicFieldType,
  QuotationStatus
} from 'App/types/graphql'
import { DocumentNode } from 'graphql'
import { get } from 'lodash'
import moment from 'moment'

export const rootKeys = ['remarks', 'serviceTypes', 'quotationUuid']

export type DynamicFieldQueryGraphQLArgs = {
  query: DocumentNode | null
  variables?: any
  getData?: (data: any) => any
  valueFormatter?: (value: any) => any
  labelFormatter?: (value: any) => any
}

export const getDynamicFieldQuery = (dynamicField: DynamicField): DynamicFieldQueryGraphQLArgs => {
  switch (dynamicField.query) {
    case DynamicFieldQuery.Department:
      return {
        query: DEPARTMENT_QUERY,
        variables: { key: 'bookingDepartments' },
        getData: (data: any) => {
          const val = data?.setting?.setting?.[0]?.value
          return val ? JSON.parse(val).departments : []
        }
      }
    case DynamicFieldQuery.Quotation:
      return {
        query: QUOTATION_QUERY,
        variables: {
          statuses: QuotationStatus.Activated
        },
        getData: (data: any) => {
          return data?.quotations?.rows
        },
        labelFormatter: (item) => item.quotationNo,
        valueFormatter: (item) => item.quotationNo
      }
    case DynamicFieldQuery.Company:
      return {
        query: GET_COMPANIES_QUERY,
        variables: {
          types: dynamicField.companyTypes
        },
        getData: (data: any) => {
          return data?.companies?.rows
        },
        labelFormatter: (item) => item.name,
        valueFormatter: (item) => item.uuid
      }
    default:
      return {
        query: null
      }
  }
}

const getDateFieldPath = (key: string, mode: FormMode = FormMode.Create, booking: Booking = {}) => {
  let path = `details.${key}`

  if (mode === FormMode.Edit) {
    const dateIndex =
      booking?.chronologies?.findIndex((chronology) => chronology?.type === key) || -1
    path = dateIndex >= 0 ? `chronologies[${dateIndex}].date` : path
  }

  return path
}

export const getDynamicFieldLookupKey = (
  key: string,
  mode: FormMode = FormMode.Create,
  type?: DynamicFieldType | null,
  booking?: Booking | null
) => {
  let fieldKey: any = rootKeys.includes(key || '') ? key || '' : `details.${key}`

  if (type === DynamicFieldType.Date) {
    fieldKey = getDateFieldPath(key, mode, booking || {})
  }

  return fieldKey
}

export const getDynamicFieldInitialValue = (
  key: string,
  dynamicField: DynamicField,
  booking: Booking | any = {},
  mode: FormMode = FormMode.Create
) => {
  let initialValue = get(booking, key)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [prefixKey, suffixKey] = key.split('.')

  if (dynamicField.type === DynamicFieldType.Date) {
    initialValue = getChronologyDate(booking?.type, suffixKey)
  }

  if (mode === FormMode.Edit) {
    if (dynamicField.type === DynamicFieldType.Date) {
      initialValue = moment(initialValue || '')
    }

    if (dynamicField.type === DynamicFieldType.Selector) {
      if (dynamicField.query === DynamicFieldQuery.Company) {
        const company = get(booking, `details.${key}`) || get(booking, key) || {}
        initialValue = company.uuid
      }
    }
  }

  if (!initialValue) {
    initialValue = dynamicField.multiple ? [] : ''
  }

  if (dynamicField.query === DynamicFieldQuery.CustomValues && !dynamicField.customValues) {
    initialValue = initialValue || undefined
  }

  return initialValue || undefined
}

export const DYNAMIC_FIELD_DATE_FORMAT = 'DD/MM/YYYY HH:mm'
