import { useEffect, useState } from 'react'
import { useLazyQuery, gql } from '@apollo/client'
import { bindActionCreators } from 'redux'
import { withApollo } from 'react-apollo'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Spin, Tooltip } from 'antd'
import { find, delay } from 'lodash'
import { compose } from 'recompose'

import * as voucherActions from 'App/states/reducers/voucher'
import CostItems from 'App/components/Voucher/CostItems'
import { COST_ITEMS_QUERY } from 'App/graphql/costItem'
import { BookingStatus } from 'App/types/graphql'
import { Booking } from 'App/types/graphql'

const Td = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

type BookingRowType = {
  referenceNum: string
  client: any
  voucherBookings: any
  updateVoucherBookings: any
  onSelectSingleCostItem: any
  onCostItemAdded: any
  index: number
  addBookingRow: (booking: Booking, index: number) => void
}

const enhance = compose(
  connect(
    (state: any) => ({
      voucherBookings: state.voucher.voucherBookings
    }),
    (dispatch: any) => ({
      dispatch,
      // @ts-ignore
      ...bindActionCreators({ ...voucherActions },
        dispatch
      )
    })
  ),
  withApollo
)

const BOOKINGS_SEARCH_QUERY = gql`
  query invoiceBookingsByBulk($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

const BookingRow = (props: BookingRowType) => {
  const {
    client,
    referenceNum,
    onCostItemAdded,
    onSelectSingleCostItem,
    voucherBookings,
    updateVoucherBookings,
    index,
    addBookingRow
  } = props

  const [loading, setLoading] = useState(true)

  const [errorMsg, setErrorMsg] = useState('')

  const [getCostItems, { data: costItemsData }] = useLazyQuery(COST_ITEMS_QUERY, {
    client,
    fetchPolicy: 'cache-and-network'
  })

  const [getBookingSearch, { loading: bookingLoading, error: bookingError, data: bookingData }] =
    useLazyQuery(BOOKINGS_SEARCH_QUERY, {
      client,
      fetchPolicy: 'cache-and-network'
    })

  useEffect(() => {
    if (!referenceNum) {
      return
    }

    delay(() => {
      getBookingSearch({
        variables: {
          input: {
            q: referenceNum,
            limit: 1,
            filter: {
              status: [
                BookingStatus.Accepted,
                BookingStatus.CloseFinance,
                BookingStatus.CloseOperation
              ]
            }
          }
        }
      })
    }, index * 500)
  }, [referenceNum])

  useEffect(() => {
    const bookings = bookingData?.bookingsSearchJson
    if (bookingLoading || !bookings) {
      return
    }

    if (bookingError || !bookings.pageInfo.count) {
      setErrorMsg('No results found.')
      setLoading(false)
      return
    }

    if (bookings.pageInfo.count > 1) {
      setErrorMsg(`${bookings.pageInfo.count} bookings found. Cannot add.`)
      setLoading(false)
      return
    }

    getCostItems({ variables: { uuid: bookings.rows[0].uuid } })
  }, [bookingLoading, bookingError, bookingData])

  useEffect(() => {
    const booking = bookingData?.bookingsSearchJson?.rows[0]
    if (booking && costItemsData) {
      const isFound = find(voucherBookings, (v: any) => v.uuid === booking.uuid)

      if (!isFound) {
        const updatedVoucherBookings = [...voucherBookings]
        updatedVoucherBookings.push({ ...booking, costItems: costItemsData })
        updateVoucherBookings(updatedVoucherBookings)
      }
    }
    setLoading(false)
  }, [costItemsData])

  const refetch = (uuid: string) => getCostItems({ variables: { uuid } })

  const renderTable = () => {
    const bookingRes = bookingData?.bookingsSearchJson?.rows[0]
    if (bookingRes) {
      const booking = bookingRes
      booking.costItems = costItemsData?.costItems
      addBookingRow(bookingRes, index)
    }

    return (
      <tr>
        <Td>
          <>
            {referenceNum}
            {loading && <Spin />}
          </>
          {costItemsData?.costItems && bookingRes && (
            <Tooltip
              title={`${bookingRes.no} - ${bookingRes.details?.shipperRef} - ${bookingRes.details?.references}`}
            >
              -{' '}
              {bookingRes?.no && (
                <a href={`/bookings/${bookingRes?.uuid}`} target="booking">
                  {bookingRes.no}
                </a>
              )}
              <br />
              {bookingRes.billTo?.name}
            </Tooltip>
          )}
        </Td>
        <Td>
          {costItemsData?.costItems && bookingRes && (
            <Tooltip title={`${bookingRes.shipper?.name} -> ${bookingRes.consignee?.name}`}>
              {bookingRes.shipper?.name}
              <br />
              {bookingRes.consignee?.name}
            </Tooltip>
          )}
        </Td>

        <td>
          {costItemsData?.costItems && bookingRes && (
            <CostItems
              compact
              voucherBooking={{ ...bookingRes, costItems: costItemsData.costItems }}
              onSelectSingleCostItem={onSelectSingleCostItem}
              onCostItemAdded={onCostItemAdded}
              onCostItemEdited={refetch}
            />
          )}
        </td>
      </tr>
    )
  }

  const renderError = () => (
    <tr>
      <td colSpan={2}>
        {referenceNum} - {loading && <Spin />}
        <br />
        {errorMsg && (
          <Tooltip title={`${errorMsg}`}>
            <span style={{ color: 'red' }}>{errorMsg}</span>
          </Tooltip>
        )}
      </td>
    </tr>
  )

  return <>{loading || errorMsg ? renderError() : renderTable()}</>
}

// @ts-ignore
export default enhance(BookingRow)
