import { WrappedFormUtils } from 'antd/lib/form/Form'

import { DATA_META_OBJECT_TYPES } from 'App/utils/documentGenerator'

type FormType = WrappedFormUtils | null

import { GeneratedDocumentStatus } from 'App/types/graphql'

export class DocumentGeneratorFormHandler {
  generatedDocument: any
  bookingsForm: FormType
  dataForm: FormType

  constructor(generatedDocument: any, bookingsForm: FormType, dataForm: FormType) {
    this.generatedDocument = generatedDocument
    this.bookingsForm = bookingsForm
    this.dataForm = dataForm
  }

  validateAssociatedBookingsForm = (): [boolean, any] => {
    let input = {}
    let formValid = false

    if (!this.bookingsForm) {
      throw new Error('Bookings form is not set')
    }

    this.bookingsForm.validateFields((err, values) => {
      if (err) return

      input = {
        ...input,
        ...values
      }

      formValid = true
    })

    return [formValid, input]
  }

  validateDataForm = (): [boolean, any] => {
    let input = {}
    let formValid = false

    if (!this.dataForm) {
      throw new Error('Data form is not set')
    }

    this.dataForm.validateFields((err, values) => {
      if (err) return

      for (const key in values.data) {
        if (DATA_META_OBJECT_TYPES.includes(this.generatedDocument.dataMeta[key].__type)) {
          values.data[key] = JSON.parse(values.data[key])
        }
      }
      input = {
        ...input,
        ...values
      }

      formValid = true
    })

    return [formValid, input]
  }

  validateAllForms = (): [boolean, any, any] => {
    const [associatedBookingsFormValid, associatedBookingsInput] =
      this.validateAssociatedBookingsForm()
    const [dataFormValid, dataInput] = this.validateDataForm()

    const allFormsValid = associatedBookingsFormValid && dataFormValid

    return [allFormsValid, associatedBookingsInput, dataInput]
  }

  updateGeneratedDocumentAssociatedBookings = async (
    updateGeneratedDocumentMutation: (args: any) => any
  ): Promise<[boolean, any]> => {
    const [associatedBookingsFormValid, associatedBookingsInput] =
      this.validateAssociatedBookingsForm()

    let success = false
    let result: any

    try {
      if (associatedBookingsFormValid) {
        const variables = {
          uuid: this.generatedDocument.uuid,
          input: {
            ...associatedBookingsInput
          }
        }

        const { data } = await updateGeneratedDocumentMutation({ variables })

        success = true
        result = data
      }
    } catch (error) {
      success = false
      result = error
    }

    return [success, result]
  }

  updateGeneratedDocumentData = async (
    updateGeneratedDocumentMutation: (args: any) => any
  ): Promise<[boolean, any]> => {
    const [formValid, dataInput] = this.validateDataForm()

    let success = false
    let result: any

    try {
      if (formValid) {
        const variables = {
          uuid: this.generatedDocument.uuid,
          input: {
            ...dataInput
          }
        }

        const { data } = await updateGeneratedDocumentMutation({ variables })

        success = true
        result = data
      }
    } catch (error) {
      success = false
      result = error
    }

    return [success, result]
  }

  updateGeneratedDocument = async (
    updateGeneratedDocumentMutation: (args: any) => any
  ): Promise<[boolean, any]> => {
    const [allFormsValid, associatedBookingsInput, dataInput] = this.validateAllForms()

    let success = false
    let result: any

    try {
      if (allFormsValid) {
        const variables = {
          uuid: this.generatedDocument.uuid,
          input: {
            ...associatedBookingsInput,
            ...dataInput
          }
        }

        const { data } = await updateGeneratedDocumentMutation({ variables })

        success = true
        result = data
      }
    } catch (error) {
      success = false
      result = error
    }

    return [success, result]
  }

  updateGeneratedDocumentStatus = async (
    updateGeneratedDocumentStatusMutation: (args: any) => any,
    status: GeneratedDocumentStatus
  ): Promise<[boolean, any]> => {
    const [dataFormValid] = this.validateDataForm()

    const allFormsValid = dataFormValid

    let success = false
    let result: any

    try {
      if (allFormsValid) {
        const variables = {
          uuid: this.generatedDocument.uuid,
          status
        }

        const { data } = await updateGeneratedDocumentStatusMutation({ variables })

        success = true
        result = data
      }
    } catch (error) {
      success = false
      result = error
    }

    return [success, result]
  }

  submitGeneratedDocument = async (
    submitGeneratedDocumentMutation: (args: any) => any
  ): Promise<[boolean, any]> => {
    const [dataFormValid] = this.validateDataForm()

    const allFormsValid = dataFormValid

    let success = false
    let result: any

    try {
      if (allFormsValid) {
        const variables = {
          uuid: this.generatedDocument.uuid
        }

        const { data } = await submitGeneratedDocumentMutation({ variables })

        success = true
        result = data
      }
    } catch (error) {
      success = false
      result = error
    }

    return [success, result]
  }

  populateGeneratedDocument = async (
    populateGeneratedDocumentMutation: (args: any) => any,
    type?: string // Replace with actual codegen enum later.
  ): Promise<[boolean, any]> => {
    const [dataFormValid] = this.validateDataForm()

    let success = false
    let result: any

    try {
      if (dataFormValid) {
        const variables = {
          uuid: this.generatedDocument.uuid,
          ...(type && { type })
        }

        const { data } = await populateGeneratedDocumentMutation({ variables })

        success = true
        result = data
      }
    } catch (error) {
      success = false
      result = error
    }

    return [success, result]
  }
}
