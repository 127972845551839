import { useMutation } from '@apollo/client'
import { Button, Popconfirm } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBookingStore } from 'App/store/booking'
import respHandler from 'App/utils/responseHandler'
import { useClient } from 'App/hooks/useClient'
import { SUBMIT_GENERATED_DOCUMENT } from '../../../../schema'
import { DocumentGeneratorFormHandler } from './utils'

interface GenericButtonProps {
  generatedDocument: any
  onChange?: () => void
}

const SubmitButton = (props: GenericButtonProps) => {
  const { generatedDocument, onChange = () => {} } = props

  const { t } = useTranslation()

  const documentGeneratorDataForm = useBookingStore.use.documentGeneratorDataForm()

  const client = useClient()

  const [submitGeneratedDocument] = useMutation(SUBMIT_GENERATED_DOCUMENT, {
    client
  })

  const [loading, setLoading] = useState(false)
  const handleConfirm = async () => {
    setLoading(true)

    const formHandler = new DocumentGeneratorFormHandler(
      generatedDocument,
      null,
      documentGeneratorDataForm
    )

    const [success, result] = await formHandler.submitGeneratedDocument(submitGeneratedDocument)

    setLoading(false)

    if (success) {
      onChange()
      respHandler(t('booking.documentGenerator.documentSubmitted'), 'success')
    } else {
      onChange()
      respHandler(result, 'error')
    }
  }

  return (
    <Popconfirm
      title={t('booking.documentGenerator.documentSubmissionConfirmation')}
      onConfirm={handleConfirm}
      okText={t('common.yes')}
      okType='primary'
      cancelText={t('common.no')}
    >
      <Button type='primary' loading={loading}>
        {t('common.submit')}
      </Button>
    </Popconfirm>
  )
}

export default SubmitButton
