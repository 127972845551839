import { Suspense } from 'react'
import { ApolloProvider } from 'react-apollo'
import { Route, Switch, useHistory } from 'react-router-dom'

import Index from 'App/pages/index'
import Bookings from 'App/pages/bookings'
import SignIn from 'App/pages/auth/SignIn'
import BookingNew from 'App/pages/bookings/new'
import UniversalSignIn from 'App/pages/auth/UniversalSignIn'

import initApollo from 'App/libs/initApollo'
import lazyLoad from 'App/utils//retryLazyLoad'
import Fallback from 'App/components/Spinner/Fallback'

const CheckIn = lazyLoad(() => import('./pages/auth/CheckIn'))
const SignOff = lazyLoad(() => import('./pages/auth/SignOff'))
const ResetPassword = lazyLoad(() => import('./pages/auth/ResetPassword'))
const ActivateAccount = lazyLoad(() => import('./pages/activate/account'))

const BookingEdit = lazyLoad(() => import('./pages/bookings/edit'))
const BookingDocument = lazyLoad(() => import('./pages/bookings/document'))
const BookingDuplicate = lazyLoad(() => import('./pages/bookings/duplicate'))
const BookingCostSheet = lazyLoad(() => import('./pages/bookings/cost-sheet'))
const BookingCoverPage = lazyLoad(() => import('./pages/bookings/cover-page'))

const DocumentCreatorView = lazyLoad(() => import('./pages/documentCreator/view'))
const DocumentsGenerated = lazyLoad(() => import('./pages/documents-generated'))

const Dashboard = lazyLoad(() => import('./pages/dashboard'))
const BulkBooking = lazyLoad(() => import('./pages/newBookingBulk'))
const BulkUploadDocument = lazyLoad(() => import('./pages/bookings/new/bulk-import-documents'))
const BulkUpdate = lazyLoad(() => import('./pages/bulk-function'))

const ReportsLegacyView = lazyLoad(() => import('./pages/reports/view'))
const ReportsLegacyIndex = lazyLoad(() => import('./pages/reports/index'))

const ReportsPage = lazyLoad(() => import('./pages/reports-new/index'))
const ViewReportPage = lazyLoad(() => import('./pages/reports-new/view'))
const CreateReportPage = lazyLoad(() => import('./pages/reports-new/create'))

const SuperAdminAreaIndex = lazyLoad(() => import('./pages/superadmin/index'))
const SuperAdminsIndex = lazyLoad(() => import('./pages/superadmin/superadmins/index'))
const SuperAdminsCreate = lazyLoad(() => import('./pages/superadmin/superadmins/create'))
const SuperAdminBaseCompaniesView = lazyLoad(() => import('./pages/superadmin/base-companies/view'))
const SuperAdminBaseCompaniesIndex = lazyLoad(() => import('./pages/superadmin/base-companies/index'))
const SuperAdminBaseCompaniesCreate = lazyLoad(() => import('./pages/superadmin/base-companies/create'))

const TransportDashboardPage = lazyLoad(() => import('./pages/transport/TransportDashboardPage'))
const EmptyTripsPage = lazyLoad(() => import('./pages/transport/EmptyTripsPage'))
// const TripSummaryPage = lazyLoad(() => import('./pages/transport/TripSummaryPage'))
const LegActivitiesReportPage = lazyLoad(() => import('./pages/transport/LegActivitiesPage'))
const RolesPage = lazyLoad(() => import('./pages/manage/roles'))

const LogsPage = lazyLoad(() => import('./pages/manage/logs'))
const CountersPage = lazyLoad(() => import('./pages/manage/counters'))
const CurrenciesPage = lazyLoad(() => import('./pages/manage/currencies'))
const PermissionsPage = lazyLoad(() => import('./pages/manage/permissions'))

const UserSettingsPage = lazyLoad(() => import('./pages/manage/user-settings'))
const BaseCompanySettings = lazyLoad(() => import('./pages/manage/base-company-settings'))


const ChargeCategoriesPage = lazyLoad(() => import('./pages/manage/charge-categories'))
const ViewChargeCategoriesPage = lazyLoad(() => import('./pages/manage/charge-categories/view'))
const CreateChargeCategoriesPage = lazyLoad(() => import('./pages/manage/charge-categories/create'))


const ChargeItemsPage = lazyLoad(() => import('./pages/manage/charge-items'))
const ViewChargeItemsPage = lazyLoad(() => import('./pages/manage/charge-items/view'))
const CreateChargeItemsPage = lazyLoad(() => import('./pages/manage/charge-items/create'))

const PortalCompany = lazyLoad(() => import('./pages/manage/portal-company'))
const ViewPortalCompany = lazyLoad(() => import('./pages/manage/portal-company/view'))
const CreatePortalCompany = lazyLoad(() => import('./pages/manage/portal-company/create'))

const Companies = lazyLoad(() => import('./pages/manage/companies'))
const ViewCompanies = lazyLoad(() => import('./pages/manage/companies/view'))
const CreateCompanies = lazyLoad(() => import('./pages/manage/companies/create'))

const DocumentCreatorTemplatesPage = lazyLoad(() => import('./pages/manage/document-creator-templates/view'))
const DocumentCreatorTableTemplatesPage = lazyLoad(() => import('./pages/manage/document-creator-templates'))
const CreateDocumentCreatorTemplatesPage = lazyLoad(() => import('./pages/manage/document-creator-templates/create'))

const DocumentGeneratorTemplatesPage = lazyLoad(() => import('./pages/manage/document-generator-templates/view'))
const DocumentGeneratorTableTemplatesPage = lazyLoad(() => import('./pages/manage/document-generator-templates'))
const CreateDocumentGeneratorTemplatesPage = lazyLoad(() => import('./pages/manage/document-generator-templates/create'))

const Employees = lazyLoad(() => import('./pages/manage/employees'))
const ViewEmployee = lazyLoad(() => import('./pages/manage/employees/view'))
const CreateEmployee = lazyLoad(() => import('./pages/manage/employees/create'))


const ExchangeRatesPage = lazyLoad(() => import('./pages/manage/exchange-rates'))
const ViewExchangeRatePage = lazyLoad(() => import('./pages/manage/exchange-rates/view'))
const CreateExchangeRatePage = lazyLoad(() => import('./pages/manage/exchange-rates/create'))


const FafsPage = lazyLoad(() => import('./pages/manage/fafs/'))
const ViewFafPage = lazyLoad(() => import('./pages/manage/fafs/view'))
const CreateFafPage = lazyLoad(() => import('./pages/manage/fafs/create'))


const GlCodesPage = lazyLoad(() => import('./pages/manage/glcodes'))
const ViewGlCodePage = lazyLoad(() => import('./pages/manage/glcodes/view'))
const CreateGlCodePage = lazyLoad(() => import('./pages/manage/glcodes/create'))

const BillingUnitsPage = lazyLoad(() => import('./pages/manage/billing-units'))
const ViewBillingUnitPage = lazyLoad(() => import('./pages/manage/billing-units/view'))
const CreateBillingUnitPage = lazyLoad(() => import('./pages/manage/billing-units/create'))


const IntegrationDetailsPage = lazyLoad(() => import('./pages/manage/integration-details'))
const ViewIntegrationDetailPage = lazyLoad(() => import('./pages/manage/integration-details/view'))
const CreateIntegrationDetailsPage = lazyLoad(() => import('./pages/manage/integration-details/create'))


const IntegrationLogsPage = lazyLoad(() => import('./pages/manage/integration-logs'))
const ViewIntegrationLogPage = lazyLoad(() => import('./pages/manage/integration-logs/view'))


const IntegrationMappingsPage = lazyLoad(() => import('./pages/manage/integration-mappings'))
const ViewIntegrationMappingPage = lazyLoad(() => import('./pages/manage/integration-mappings/view'))
const CreateIntegrationMappingPage = lazyLoad(() => import('./pages/manage/integration-mappings/create'))

const RatesPage = lazyLoad(() => import('./pages/manage/rates'))
const CreateRatePage = lazyLoad(() => import('./pages/manage/rates/create'))
const ViewRatePage = lazyLoad(() => import('./pages/manage/rates/view'))

const IntegrationBulkImportFunctionsPage = lazyLoad(() => import('./pages/manage/integration-bulk-import-functions'))
const ViewIntegrationBulkImportFunctionsPage = lazyLoad(() => import('./pages/manage/integration-bulk-import-functions/view'))
const CreateIntegrationBulkImportFunctionsPage = lazyLoad(() => import('./pages/manage/integration-bulk-import-functions/create'))


const QuotationsPage = lazyLoad(() => import('./pages/manage/quotations'))
const ViewQuotations = lazyLoad(() => import('./pages/manage/quotations/view'))
const CreateQuotationsPage = lazyLoad(() => import('./pages/manage/quotations/create'))


const TaxesPage = lazyLoad(() => import('./pages/manage/taxes'))
const ViewTaxes = lazyLoad(() => import('./pages/manage/taxes/view'))
const CreateTaxPage = lazyLoad(() => import('./pages/manage/taxes/create'))

const TransportRatesPage = lazyLoad(() => import('./pages/manage/transport-rates'))
const ViewTransportRatePage = lazyLoad(() => import('./pages/manage/transport-rates/view'))
const CreateTransportRatePage = lazyLoad(() => import('./pages/manage/transport-rates/create'))
const DuplicateTransportRatePage = lazyLoad(() => import('./pages/manage/transport-rates/duplicate'))

const TransportAreaCodesPage = lazyLoad(() => import('./pages/manage/transport-area-codes'))
const ViewTransportAreaCodesPage = lazyLoad(() => import('./pages/manage/transport-area-codes/view'))
const CreateTransportAreaCodesPage = lazyLoad(() => import('./pages/manage/transport-area-codes/create'))

const Vouchers = lazyLoad(() => import('./pages/vouchers'))
const VouchersView = lazyLoad(() => import('./pages/vouchers/view'))
const VouchersAdvice = lazyLoad(() => import('./pages/vouchers/advice'))
const VouchersSummary = lazyLoad(() => import('./pages/vouchers/summary'))

const TransportShift = lazyLoad(() => import('./pages/transport/shift'))
const TransportVehiclePage = lazyLoad(() => import('./pages/transport/Vehicle'))
const TransportOperation = lazyLoad(() => import('./pages/transport/operation'))
const TransportManifests = lazyLoad(() => import('./pages/transport/manifests'))
const TransportPlanningPage = lazyLoad(() => import('./pages/transport/planning'))
const TransportUnplannedPage = lazyLoad(() => import('./pages/transport/Unplanned'))
const JobMonitoringPage = lazyLoad(() => import('./pages/transport/JobMonitoringPage'))
const TransportIncentiveVoucher = lazyLoad(() => import('./pages/transport/incentiveVoucher'))


const IncentiveRatesPage = lazyLoad(() => import('./pages/manage/incentive-rates'))
const ViewIncentiveRatePage = lazyLoad(() => import('./pages/manage/incentive-rates/view'))
const CreateIncentiveRatePage = lazyLoad(() => import('./pages/manage/incentive-rates/create'))
const DuplicateIncentiveRatePage = lazyLoad(() => import('./pages/manage/incentive-rates/duplicate'))
const TransportZonePage = lazyLoad(() => import('./pages/manage/transport-zones'))
const CreateTransportZonePage = lazyLoad(() => import('./pages/manage/transport-zones/Create'))
const ViewTransportZonePage = lazyLoad(() => import('./pages/manage/transport-zones/View'))
const JobTypePage = lazyLoad(() => import('./pages/manage/job-types'))
const ViewJobTypePage = lazyLoad(() => import('./pages/manage/job-types/View'))
const CreateJobTypePage = lazyLoad(() => import('./pages/manage/job-types/Create'))


const TransportJobTypePage = lazyLoad(() => import('./pages/manage/transport-job-types'))
const ViewTransportJobTypePage = lazyLoad(() => import('./pages/manage/transport-job-types/View'))
const CreateTransportJobTypePage = lazyLoad(() => import('./pages/manage/transport-job-types/Create'))


const BookingTypePage = lazyLoad(() => import('./pages/manage/booking-types'))
const ViewBookingTypePage = lazyLoad(() => import('./pages/manage/booking-types/View'))
const CreateBookingTypePage = lazyLoad(() => import('./pages/manage/booking-types/Create'))


const ContainerTypePage = lazyLoad(() => import('./pages/manage/container-types'))
const ViewContainerTypePage = lazyLoad(() => import('./pages/manage/container-types/View'))
const CreateContainerTypePage = lazyLoad(() => import('./pages/manage/container-types/Create'))


const ContainerGradePage = lazyLoad(() => import('./pages/manage/container-grades'))
const ViewContainerGradePage = lazyLoad(() => import('./pages/manage/container-grades/View'))
const CreateContainerGradePage = lazyLoad(() => import('./pages/manage/container-grades/Create'))


const VolumeUnitPage = lazyLoad(() => import('./pages/manage/volume-units'))
const ViewVolumeUnitPage = lazyLoad(() => import('./pages/manage/volume-units/View'))
const CreateVolumeUnitPage = lazyLoad(() => import('./pages/manage/volume-units/Create'))


const WeightUnitPage = lazyLoad(() => import('./pages/manage/weight-units'))
const ViewWeightUnitPage = lazyLoad(() => import('./pages/manage/weight-units/View'))
const CreateWeightUnitPage = lazyLoad(() => import('./pages/manage/weight-units/Create'))


const JobOptionPage = lazyLoad(() => import('./pages/manage/job-options'))
const ViewJobOptionPage = lazyLoad(() => import('./pages/manage/job-options/View'))
const CreateJobOptionPage = lazyLoad(() => import('./pages/manage/job-options/Create'))


const IncentiveTypesPage = lazyLoad(() => import('./pages/manage/IncentiveTypes'))
const ViewIncentiveTypePage = lazyLoad(() => import('./pages/manage/IncentiveTypes/View'))
const CreateIncentiveTypePage = lazyLoad(() => import('./pages/manage/IncentiveTypes/Create'))


const YardsPage = lazyLoad(() => import('./pages/manage/yards'))
const ViewYardsPage = lazyLoad(() => import('./pages/manage/yards/view'))
const CreateYardsPage = lazyLoad(() => import('./pages/manage/yards/create'))

const AppRouter = (props) => {

  const history = useHistory()
  const apollo = initApollo({}, { headers: props.headers, router: history })

  return (
    <ApolloProvider client={apollo}>
      <Suspense fallback={<Fallback />}>
        <Switch>
          {/* Manage Pages Start */}
          {/*
              <Route path='/manage/company-invites/create' component={CreateInvitesPage} />
              <Route path='/manage/company-invites/:uuid' component={ViewInvitePage} />
              <Route path='/manage/company-access' component={CompaniesAccess} />
              <Route path='/manage/company-invites' component={InvitesPage} />
            */}

          <Route path="/manage/document-creator-templates/create" component={CreateDocumentCreatorTemplatesPage} />
          <Route path="/manage/document-creator-templates/:uuid" component={DocumentCreatorTemplatesPage} />
          <Route path="/manage/document-creator-templates" component={DocumentCreatorTableTemplatesPage} />

          <Route path="/manage/document-generator-templates/create" component={CreateDocumentGeneratorTemplatesPage} />
          <Route path="/manage/document-generator-templates/:uuid" component={DocumentGeneratorTemplatesPage} />
          <Route path="/manage/document-generator-templates" component={DocumentGeneratorTableTemplatesPage} />

          <Route path="/manage/integration-details/create" component={CreateIntegrationDetailsPage} />
          <Route path="/manage/integration-details/:uuid" component={ViewIntegrationDetailPage} />
          <Route path="/manage/integration-details" component={IntegrationDetailsPage} />

          <Route path="/manage/fafs/create" component={CreateFafPage} />
          <Route path="/manage/fafs/:uuid" component={ViewFafPage} />
          <Route path="/manage/fafs" component={FafsPage} />

          <Route path="/manage/taxes/create" component={CreateTaxPage} />
          <Route path="/manage/taxes/:uuid" component={ViewTaxes} />
          <Route path="/manage/taxes" component={TaxesPage} />

          <Route path="/manage/currencies" component={CurrenciesPage} />

          <Route path="/manage/exchange-rates/create" component={CreateExchangeRatePage} />
          <Route path="/manage/exchange-rates/:uuid" component={ViewExchangeRatePage} />
          <Route path="/manage/exchange-rates" component={ExchangeRatesPage} />

          <Route path="/manage/glcodes/create" component={CreateGlCodePage} />
          <Route path="/manage/glcodes/:uuid" component={ViewGlCodePage} />
          <Route path="/manage/glcodes" component={GlCodesPage} />

          <Route path="/manage/billing-units/create" component={CreateBillingUnitPage} />
          <Route path="/manage/billing-unit/:uuid" component={ViewBillingUnitPage} />
          <Route path="/manage/billing-units" component={BillingUnitsPage} />

          <Route path="/manage/integration-mappings/create" component={CreateIntegrationMappingPage} />
          <Route path="/manage/integration-mappings/:uuid" component={ViewIntegrationMappingPage} />
          <Route path="/manage/integration-mappings" component={IntegrationMappingsPage} />
          <Route path="/manage/integration-logs/:uuid" component={ViewIntegrationLogPage} />
          <Route path="/manage/integration-logs" component={IntegrationLogsPage} />

          <Route path="/manage/integration-bulk-import-functions/create" component={CreateIntegrationBulkImportFunctionsPage} />
          <Route path="/manage/integration-bulk-import-functions/:uuid" component={ViewIntegrationBulkImportFunctionsPage} />
          <Route path="/manage/integration-bulk-import-functions" component={IntegrationBulkImportFunctionsPage} />

          <Route path="/manage/logs/:category" component={LogsPage} />
          <Route path="/manage/logs/" component={LogsPage} />

          <Route path="/manage/base-company-settings" component={BaseCompanySettings} />

          <Route path="/manage/user-settings" component={UserSettingsPage} />

          <Route path="/manage/transport-rates/create/duplicate/:uuid" component={DuplicateTransportRatePage} />
          <Route path="/manage/transport-rates/create" component={CreateTransportRatePage} />
          <Route path="/manage/transport-rates/:uuid" component={ViewTransportRatePage} />
          <Route path="/manage/transport-rates" component={TransportRatesPage} />

          <Route path="/manage/rates/create" component={CreateRatePage} />
          <Route path="/manage/rates/:_id" component={ViewRatePage} />
          <Route path="/manage/rates" component={RatesPage} />

          <Route path="/manage/incentive-rates/create/duplicate/:uuid" component={DuplicateIncentiveRatePage} />
          <Route path="/manage/incentive-rates/create" component={CreateIncentiveRatePage} />
          <Route path="/manage/incentive-rates/:uuid" component={ViewIncentiveRatePage} />
          <Route path="/manage/incentive-rates" component={IncentiveRatesPage} />
          <Route path="/manage/incentive-types/create" component={CreateIncentiveTypePage} />
          <Route path="/manage/incentive-types/:uuid" component={ViewIncentiveTypePage} />
          <Route path="/manage/incentive-types" component={IncentiveTypesPage} />

          <Route path="/manage/yards/create" component={CreateYardsPage} />
          <Route path="/manage/yards/:uuid" component={ViewYardsPage} />
          <Route path="/manage/yards" component={YardsPage} />

          <Route path="/manage/transport-area-codes/create" component={CreateTransportAreaCodesPage} />
          <Route path="/manage/transport-area-codes/:uuid" component={ViewTransportAreaCodesPage} />
          <Route path="/manage/transport-area-codes" component={TransportAreaCodesPage} />

          <Route path="/manage/transport-zones/create" component={CreateTransportZonePage} />
          <Route path="/manage/transport-zones/:uuid" component={ViewTransportZonePage} />
          <Route path="/manage/transport-zones" component={TransportZonePage} />

          <Route path="/manage/job-types/create" component={CreateJobTypePage} />
          <Route path="/manage/job-types/:uuid" component={ViewJobTypePage} />
          <Route path="/manage/job-types" component={JobTypePage} />

          <Route path="/manage/transport-job-types/create" component={CreateTransportJobTypePage} />
          <Route path="/manage/transport-job-types/:uuid" component={ViewTransportJobTypePage} />
          <Route path="/manage/transport-job-types" component={TransportJobTypePage} />

          <Route path="/manage/job-options/create" component={CreateJobOptionPage} />
          <Route path="/manage/job-options/:uuid" component={ViewJobOptionPage} />
          <Route path="/manage/job-options" component={JobOptionPage} />

          <Route path="/manage/container-grades/create" component={CreateContainerGradePage} />
          <Route path="/manage/container-grades/:uuid" component={ViewContainerGradePage} />
          <Route path="/manage/container-grades" component={ContainerGradePage} />

          <Route path="/manage/container-types/create" component={CreateContainerTypePage} />
          <Route path="/manage/container-types/:uuid" component={ViewContainerTypePage} />
          <Route path="/manage/container-types" component={ContainerTypePage} />

          <Route path="/manage/booking-types/create" component={CreateBookingTypePage} />
          <Route path="/manage/booking-types/:uuid" component={ViewBookingTypePage} />
          <Route path="/manage/booking-types" component={BookingTypePage} />

          <Route path="/manage/volume-units/create" component={CreateVolumeUnitPage} />
          <Route path="/manage/volume-units/:uuid" component={ViewVolumeUnitPage} />
          <Route path="/manage/volume-units" component={VolumeUnitPage} />

          <Route path="/manage/weight-units/create" component={CreateWeightUnitPage} />
          <Route path="/manage/weight-units/:uuid" component={ViewWeightUnitPage} />
          <Route path="/manage/weight-units" component={WeightUnitPage} />

          <Route path="/manage/charge-categories/create" component={CreateChargeCategoriesPage} />
          <Route path="/manage/charge-categories/:uuid" component={ViewChargeCategoriesPage} />
          <Route path="/manage/charge-categories/" component={ChargeCategoriesPage} />
          <Route path="/manage/charge-items/create" component={CreateChargeItemsPage} />
          <Route path="/manage/charge-items/:uuid" component={ViewChargeItemsPage} />
          <Route path="/manage/charge-items" component={ChargeItemsPage} />

          <Route path="/manage/quotations/create" component={CreateQuotationsPage} />
          <Route path="/manage/quotations/:uuid" component={ViewQuotations} />
          <Route path="/manage/quotations" component={QuotationsPage} />

          <Route path="/manage/counters" component={CountersPage} />

          <Route path="/manage/roles" component={RolesPage} />

          <Route path="/manage/permissions" component={PermissionsPage} />

          <Route path="/manage/employees/create" component={CreateEmployee} />
          <Route path="/manage/employees/:uuid" component={ViewEmployee} />
          <Route path="/manage/employees" component={Employees} />

          <Route path="/manage/companies/view" component={ViewCompanies} />
          <Route path="/manage/companies/create" component={CreateCompanies} />
          <Route path="/manage/companies" component={Companies} />

          <Route path="/manage/portal-company/view" component={ViewPortalCompany} />
          <Route path="/manage/portal-company/create" component={CreatePortalCompany} />
          <Route path="/manage/portal-company" component={PortalCompany} />
          {/* Manage Pages End */}

          {/* booking */}
          <Route path="/bookings/new/upload-document" component={BulkUploadDocument} />
          <Route path="/bookings/new/bulk" component={BulkBooking} />
          <Route path="/bookings/new" component={BookingNew} />
          <Route path="/bookings/:uuid/duplicate" component={BookingDuplicate} />
          <Route path={['/booking/document/:uuid', '/bookings/document/:uuid']} component={BookingDocument} />
          <Route path={['/bookings/:uuid/cost-sheet', '/bookings/:uuid/costsheet']} component={BookingCostSheet} />
          <Route path={['/bookings/:uuid/cost-sheet/proposed', '/bookings/:uuid/costsheet/proposed']} component={BookingCostSheet} />
          <Route path="/bookings/:uuid/cover-page" component={BookingCoverPage} />
          <Route path="/bookings/:uuid/edit" component={BookingEdit} />
          <Route path="/bookings/:no" component={Bookings} />

          {/* vouchers */}
          <Route path="/vouchers/advice" component={VouchersAdvice} />
          <Route path="/vouchers/summary" component={VouchersSummary} />
          <Route path="/vouchers/:uuid/view" component={VouchersView} />
          <Route path="/vouchers/:uuid" component={Vouchers} />
          <Route path="/vouchers" component={Vouchers} />

          {/* pdf | document creator */}
          <Route path="/documents" component={DocumentsGenerated} />
          <Route path="/documentCreator/view/:uuid" component={DocumentCreatorView} />

          {/* reports */}
          <Route path="/reports/create" component={CreateReportPage} />
          <Route path="/reports/:uuid" component={ViewReportPage} />
          <Route path="/reports" component={ReportsPage} />
          <Route path="/reports-legacy/:key" component={ReportsLegacyView} />
          <Route path="/reports-legacy" component={ReportsLegacyIndex} />

          {/* superadmin */}
          <Route path="/superadmin/base-companies/create" component={SuperAdminBaseCompaniesCreate} />
          <Route path="/superadmin/base-companies/:uuid" component={SuperAdminBaseCompaniesView} />
          <Route path="/superadmin/base-companies" component={SuperAdminBaseCompaniesIndex} />
          <Route path="/superadmin/superadmins/create" component={SuperAdminsCreate} />
          <Route path="/superadmin/superadmins" component={SuperAdminsIndex} />
          <Route path="/superadmin" component={SuperAdminAreaIndex} />

          {/* monitoring */}
          <Route path="/transport/monitoring/job-status" exact component={JobMonitoringPage} />
          <Route path="/transport/monitoring/empty-trips" exact component={EmptyTripsPage} />
          <Route path="/transport/monitoring/leg-activities-report" exact component={LegActivitiesReportPage} />

          {/* transport */}
          <Route path="/transport/operation/incentiveVoucher" component={TransportIncentiveVoucher} />
          <Route path="/transport/operation/incentiveShift" component={TransportShift} />
          <Route path="/transport/operation" component={TransportOperation} />
          <Route path="/transport/manifests" component={TransportManifests} />
          <Route path="/transport/planning/vehicle" exact component={TransportVehiclePage} />
          <Route path="/transport/planning" exact component={TransportPlanningPage} />
          <Route path="/transport/planning/unplanned" exact component={TransportUnplannedPage} />
          <Route path="/transport/dashboard" exact component={TransportDashboardPage} />
          {/* <Route path="/transport/trip-summary" exact component={TripSummaryPage} /> */}

          <Route path={['/dashboard/:key/:starred', '/dashboard/:key', '/dashboard']} component={Dashboard} />

          {/* authentication related */}
          <Route path="/auth/sign-in" component={UniversalSignIn} />
          <Route path="/auth/check-in" component={CheckIn} />
          <Route path="/auth/sign-off" component={SignOff} />
          <Route path="/auth/v2/sign-in" component={SignIn} />
          <Route path="/auth/reset-password" component={ResetPassword} />

          <Route path="/activate/account" component={ActivateAccount} />

          {/* bulk update */}
          <Route path="/bulk-functions" component={BulkUpdate} />

          {/* main index */}
          <Route path="/" component={Index} />
        </Switch>
      </Suspense>
    </ApolloProvider>
  )
}

export default AppRouter
