import { gql } from '@apollo/client'
import { Job } from 'App/types/graphql'
import { isArray } from 'lodash'

export const COMPANIES_QUERY = gql`
  query companiesMultiPointBooking1(
    $types: [CompanyType!]
    $countryAlpha3: LimitedString
    $query: String
    $limit: Int
    $offset: Int
    $statuses: [CompanyStatus!]
    $uuids: [UUID]
  ) {
    companies(
      types: $types
      countryAlpha3: $countryAlpha3
      query: $query
      limit: $limit
      offset: $offset
      statuses: $statuses
      uuids: $uuids
    ) {
      rows {
        code
        name
        uuid
        addresses {
          name
          address1
          areaCode
          zone
          uuid
        }
      }
    }
  }
`

export const formatArrayString = (value: any) => {
  return isArray(value) ? value.join(', ') : value
}

export const defaultColumns = [
  {
    key: 'expandRowButton',
    title: '',
    align: 'left',
    width: 20
  },
  {
    key: 'index',
    title: 'No.',
    align: 'left',
    width: 42,
    render: (text: string, record: Job, index: number) => {
      return (
        <span>
          {index + 1} {record.no}
        </span>
      )
    }
  },
  {
    key: 'type',
    title: 'Job Type',
    render: (text: string, record: Job, index: number) => record.type
  },
  {
    key: 'measurementUnit',
    title: 'Unit',
    render: (text: string, record: Job) => {
      if (!record.details?.measurementUnit) {
        return null
      }

      return `${record.details.measurementUnit} ${record.details.uom}`
    }
  },
  {
    key: 'transportType',
    title: 'Transport Type',
    render: (text: string, record: Job, index: number) => record.details?.transportType
  },
  {
    key: 'remarks',
    title: 'Remarks',
    align: 'right',
    ellipsis: true,
    render: (text: any, record: any, index: any) => record.remarks
  }
]
