import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { Row, Spin, Table } from 'antd'
import { uniq } from 'lodash'

import { mergeAddressShort } from 'App/components/Transport/Utils/jobHelper'
import TripModal from 'App/components/Booking/General/JobsOverview/TripModal'

import { getColumns } from './getColumns'
import useJobTypes from 'App/hooks/useJobTypes'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'

import { GET_WAYBILL_TEMPLATES } from 'App/components/Transport/Schemas/schema'

import type { Address, Job, Trip } from 'App/types/graphql'
import { EyeOutlined } from '@ant-design/icons'

const rowClassName = () => 'xs-font'

const JobsOverview = memo((props: any) => {
  const { client, expandAllTrips, booking } = props

  const [jobs, setJobs] = useState(props.jobs)
  const [selectedRow, setSelectedRow] = useState<any>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const { jobTypes, requestJobTypes } = useJobTypes(client)

  const { data: templates } = useQuery(GET_WAYBILL_TEMPLATES, {
    client,
    variables: {
      q: 'WAYBILL_SLIP'
    }
  })

  const { hasPermission: canEdit } = usePermissionIsAllowed({
    client,
    resource: 'job',
    permission: 'update'
  })

  useEffect(() => {
    requestJobTypes(uniq(jobs.map((job: Job) => job.type)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs])

  useEffect(() => {
    if (expandAllTrips) {
      const keys = jobs?.map((j: Job) => j.uuid)
      setExpandedRowKeys(uniq(keys))
    } else {
      setExpandedRowKeys([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandAllTrips, jobs])

  useEffect(() => {
    if (props.jobs) {
      setJobs(props.jobs)
    }
  }, [props.jobs])

  const showModal = () => setOpenModal(true)

  const closeModal = () => setOpenModal(false)

  const columns = [
    {
      title: '',
      dataIndex: 'indentation',
      width: '42px',
      className: 'xs-font'
    },
    {
      title: 'Trip No',
      dataIndex: 'sequence',
      key: 'sequence',
      width: '70px'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: '130px'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '130px'
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      render: (text: Address) => (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {mergeAddressShort(text || {})}
        </div>
      )
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      render: (text: Address) => (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {mergeAddressShort(text || {})}
        </div>
      )
    },
    {
      render: () => (
        <EyeOutlined style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '30px' }} />
      )
    }
  ]

  const expandedRowRender = useCallback(
    (row) => {
      const data = row?.trips?.map((t: Trip) => ({ key: t.uuid, ...t }))

      return <>
        <Table
          size="middle"
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              setSelectedRow(record)
              showModal()
            }
          })}

          style={{ width: '80%', marginLeft: '50px', backgroundColor: '#F2F2F2', cursor: 'pointer' }}
        />
        <TripModal booking={booking} jobs={jobs} selectedRow={selectedRow} client={client} openModal={openModal} closeModal={closeModal} tripLocations={selectedRow} />
      </>
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates, openModal]
  )

  const onTableRowExpand = (expanded: boolean, record: Job) => {
    const keys = [...expandedRowKeys]
    if (expanded) {
      keys.push(record.uuid)
      setExpandedRowKeys(uniq(keys))
    } else {
      const index = keys.indexOf(record.uuid)
      keys.splice(index, 1)
      setExpandedRowKeys(uniq(keys))
    }
  }

  if (!jobTypes?.length) {
    return (
      <Row type='flex' justify='center'>
        <Spin />
      </Row>
    )
  }

  return (
    <Table
      rowKey={(record: Job) => record.uuid || record.jobNo}
      columns={getColumns(props, jobTypes, { canEdit })} // TODO here
      expandedRowRender={expandedRowRender}
      expandedRowKeys={expandedRowKeys}
      rowClassName={rowClassName}
      onExpand={onTableRowExpand}
      dataSource={props.jobs}
      scroll={{ x: 500 }}
      expandRowByClick
      size="small"
      pagination={{ pageSize: 10, hideOnSinglePage: true }}
    />
  )
})

export default withApollo(JobsOverview)
