import { useState } from 'react'
import { compose } from 'recompose'
import { Row, Col, Form, Button } from 'antd'
import { useLazyQuery, gql } from '@apollo/client'
import { withApollo } from 'react-apollo'

import BulkInvoiceByCustomerForm from './Form'
import UserQuery from 'App/containers/user/query/user'
import BookingRow from './BookingRow'
import { VoucherTypeType } from 'App/types/graphql'
import BookingTable from './BookingTable'

const enhance = compose(UserQuery, Form.create(), withApollo)

const BOOKINGS_SEARCH_QUERY = gql`
  query invoiceBookingsByCustomer($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

const BulkInvoiceGenerator = (props: any) => {
  const { onSelectSingleCostItem, onCostItemAdded, form, voucher, client } = props

  const initCompany =
    voucher?.transactionType === VoucherTypeType.Accrec
      ? voucher?.customer
      : voucher?.vendor

  const [selectedCompany, setSelectedCompany] = useState<any>(initCompany)

  const [facets, setFacets] = useState<string>(
    'transCompAr:transCompPendOutstandingConsigneeInvoiceVoucher'
  ) // key:value

  const [elasticBks, setElasticBks] = useState<Array<any>>([])

  const getFilters = () => {
    const obj: any = {}
    if (facets?.length) {
      // allow multi filter in single string using delimiter but prob better to just preset some filter
      facets.split(',').forEach((v: any) => {
        const words = v.trim().split(':')
        if (Array.isArray(obj[words[0]])) {
          obj[words[0]].push(words[1])
        } else {
          obj[words[0]] = [words[1]]
        }
      })
    }
    return obj
  }

  const [getBookingSearch, { loading: bookingLoading, error: bookingError, data: bookingData }] =
    useLazyQuery(BOOKINGS_SEARCH_QUERY, {
      client,
      variables: {
        input: {
          q: '',
          limit: 2000,
          filter: {
            companyUuids: [selectedCompany?.uuid],
            facetFilter: getFilters()
          }
        }
      },
      fetchPolicy: 'cache-and-network'
    })

  const search = () => {
    if (!selectedCompany?.uuid) return

    setFacets(form.getFieldValue('filter'))
    getBookingSearch()
  }

  const onHandleJobSelected = (elasticBk: any) => {
    if (!elasticBks.find((b: any) => b.uuid === elasticBk.uuid)) {
      setElasticBks([...elasticBks, elasticBk])
    }
  }

  const types = []
  const accountTypes = []
  if (voucher?.transactionType === VoucherTypeType.Accrec) { //@ts-ignore
    types.push('billing')//@ts-ignore
    accountTypes.push('debtor')
  } else if (voucher?.transactionType === VoucherTypeType.Accpay) {//@ts-ignore
    accountTypes.push('creditor')
  }

  // Todo: add a company search here.
  // Add a facet booking here
  return (
    <>
      <Row>
        <Col span={6}>
          <BulkInvoiceByCustomerForm
            form={form}
            initialValue={{
              company: {
                types,
                company: selectedCompany
              },
              facets
            }}
            handleCompanyChange={setSelectedCompany}
          />
          <Button style={{ float: 'right' }} onClick={search}>
            Search
          </Button>
        </Col>
        <Col span={18} style={{ paddingLeft: '20px' }}>
          <BookingTable
            bookings={bookingData?.bookingsSearchJson?.rows}
            onHandleJobSelected={onHandleJobSelected}
            loading={bookingLoading}
          />
        </Col>
      </Row>
      <br />
      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <th style={{ width: '10%' }}>JobNo</th>
            <th style={{ width: '10%' }}>Ship/Con</th>
            <th>CostSheet</th>
          </tr>
          {elasticBks.map((r: any) => (
            <BookingRow
              // @ts-ignore
              onSelectSingleCostItem={onSelectSingleCostItem}
              onCostItemAdded={onCostItemAdded}
              key={r.uuid}
              elasticBk={r}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}

export default enhance(BulkInvoiceGenerator)
