import { useState } from 'react'
import { v4 } from 'uuid'
import { Form, Modal, Button, Tooltip, notification } from 'antd'
import { withApollo } from 'react-apollo'

import JobForm, {
  EditMode
} from 'App/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'
import { logger } from 'App/utils/logger'
import { ADD_JOB } from 'App/graphql/booking'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { identity, pick, pickBy } from 'lodash'
import { useMutation } from '@apollo/client'
import { AddJobInput, Job, Trip } from 'App/types/graphql'

type JobAddActionProps = {
  client?: any
  shipmentType: any
  bookingUuid: string
}

const JobAddAction = (props: JobAddActionProps) => {
  const { client, bookingUuid } = props

  const [displayModal, setDisplayModal] = useState(false)
  const [jobsArray, setJobsArray] = useState([{ uuid: v4(), bookingUuid }])

  const [addJobMutation, { loading }] = useMutation(ADD_JOB, { client })

  // @ts-ignore
  const updateJobs = (jobs: Job) => setJobsArray(jobs)

  const { hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'job',
    permission: 'create'
  })

  if (!hasPermission) {
    return <></>
  }

  const handleDisplayModal = () => setDisplayModal(true)
  const handleModalCancel = () => setDisplayModal(false)

  const handleModalOk = async () => {
    const job = jobsArray[0] as any

    const tripsInput = job?.trips?.map((trip: Trip) => {
      return {
        ...pick(trip, [
          'uuid',
          'fromCompanyUuid',
          'toCompanyUuid',
          'fromUuid',
          'toUuid',
          'remarks',
          'references'
        ])
      }
    })

    const formattedJob: AddJobInput = {
      details: {
        ...pickBy(job.details, identity)
      },
      type: job.type,
      no: job.no,
      remarks: job.remarks,
      reference: job.reference,
      tripFormat: job.tripFormat,
      trips: tripsInput,
      uuid: v4(),
      bookingUuid
    }

    try {
      await addJobMutation({ variables: { input: { ...formattedJob } } })

      notification.success({
        message: 'Submit Notice',
        description: 'Successfully updated Job'
      })

      setDisplayModal(false)
    } catch (error: any) {
      logger.error('Update Job Error', error)
      return notification.error({
        message: 'Error',
        description: error.message.replace('GraphQL error: ', '')
      })
    }
  }

  return (
    <>
      <Tooltip title="Add Job">
        <Button icon="plus" shape="circle" onClick={handleDisplayModal} />
      </Tooltip>
      {displayModal && (
        <Modal
          title="Add New Job"
          visible
          width={1080}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
        >
          <Form>
            {jobsArray.map((job: Job, index: number) => (
              <JobForm
                // @ts-ignore
                allJobs={jobsArray}
                key={job.uuid}
                currentJob={job}
                jobIndex={index}
                updateJobs={updateJobs}
              />
            ))}
          </Form>
        </Modal>
      )}
    </>
  )
}

export default withApollo(JobAddAction)
