import { DatePicker, Input } from 'antd'
import { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'

import PlaceSelector from 'App/components/Booking/DynamicField/PlaceSelector'
import PortPlaceSelector from 'App/components/Booking/DynamicField/PortPlaceSelector'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import EnumSelector from 'App/components/Shared/EnumSelector'
import {
  DYNAMIC_FIELD_DATE_FORMAT,
  getDynamicFieldInitialValue,
  getDynamicFieldLookupKey,
  getDynamicFieldQuery
} from './helper'

import TagsInput from 'App/components/Shared/TagsInput/TagsInput'
import type { DynamicField } from 'App/types/graphql'
import { DynamicFieldControl, DynamicFieldQuery, DynamicFieldType } from 'App/types/graphql'
import SelectWithQuery from './SelectWithQuery'

type BookingDynamicFieldProps = {
  form: any
  mode: FormMode
  dynamicField: any
  duplicateBookingObject?: any
  client?: any
}

const BookingDynamicField = (props: BookingDynamicFieldProps) => {
  const { form, duplicateBookingObject, dynamicField, client, mode } = props

  const [query, setQuery] = useState(getDynamicFieldQuery(dynamicField))

  useEffect(() => {
    if (dynamicField.query !== DynamicFieldQuery.Quotation) return

    const billToUuid = form.getFieldValue('billToUuid')

    if (!billToUuid) return

    setQuery((prev) => ({ ...prev, variables: { ...prev.variables, buyerUuid: billToUuid } }))
  }, [form, dynamicField])

  const renderField = (props: DynamicField) => {
    const { key, query: queryType, type, customValues, multiple, isMultiline } = props

    const defaultRule = {
      required: dynamicField.control === DynamicFieldControl.Required,
      message: `${key} is required`
    }

    const fieldKey = getDynamicFieldLookupKey(key || '', mode, type, duplicateBookingObject)

    switch (type) {
      case DynamicFieldType.Selector:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          (function () {
            switch (queryType) {
              case DynamicFieldQuery.Place:
                return <PlaceSelector />
              case DynamicFieldQuery.Port:
                return <PortPlaceSelector mode={mode} />
              case DynamicFieldQuery.Enums:
                return (
                  <EnumSelector
                    client={client}
                    placeholder='Select a value...'
                    enumName={dynamicField.enumName}
                    multiple={dynamicField.multiple}
                  />
                )
              default: {
                if (queryType === DynamicFieldQuery.CustomValues && !customValues) {
                  return <TagsInput placeholder={`Enter ${key}`} />
                }

                return (
                  <SelectWithQuery
                    fieldKey={fieldKey}
                    query={query}
                    options={
                      queryType === DynamicFieldQuery.CustomValues
                        ? customValues?.split('\n') || []
                        : undefined
                    }
                    mode={
                      queryType === DynamicFieldQuery.CustomValues && !customValues
                        ? 'tags'
                        : multiple
                        ? 'multiple'
                        : 'default'
                    }
                    autoSelectFirst={queryType === DynamicFieldQuery.Quotation}
                  />
                )
              }
            }
          })()
        )
      case DynamicFieldType.Date:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          <DatePicker
            showTime
            format={DYNAMIC_FIELD_DATE_FORMAT}
            disabled={mode === FormMode.Edit}
            placeholder='Select a date and time'
            // @ts-ignore
            getCalendarContainer={(trigger) => trigger.parentNode}
          />
        )

      default:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          isMultiline ? (
            <Input.TextArea rows={4} autoComplete='off' placeholder={`Enter ${key}`} />
          ) : (
            <Input autoComplete='off' placeholder={`Enter ${key}`} />
          )
        )
    }
  }

  return renderField(dynamicField)
}

export default withApollo(BookingDynamicField)
