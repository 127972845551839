import { Button } from 'antd'
import { memo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useMutation, gql } from '@apollo/client'

import { StyledForm, StyledLegend } from './Styled'

import respHandler from 'App/utils/responseHandler'
import ChargeItemSelect from 'App/components/Select/TypeToFetch/ChargeItemSelect'

import type { CostItem } from 'App/types/graphql'

type StatusFormPropsType = {
  costItem: CostItem
  client: any
  onUpdate?: any
}

const ChangeChargeItem = memo((props: StatusFormPropsType) => {
  const { costItem, client, onUpdate } = props
  const CHANGE_CHARGE_ITEM = gql`
    mutation changeBookingCostItemChargeCodeChangeChargeItem($input: ChangeCostItemInput) {
      changeBookingCostItemChargeCode(input: $input) {
        uuid
        chargeItem {
          name
          code
        }
      }
    }
  `

  const [chargeItemUuid, setChargeItemUuid] = useState('')
  const [mutateChargeItem, { loading }] = useMutation(CHANGE_CHARGE_ITEM, { client })

  const onChange = (value: string) => {
    setChargeItemUuid(value)
  }

  const handleUpdate = async () => {
    if (!costItem.uuid) {
      respHandler('No charge item is found', 'error')
      return
    }

    respHandler('Updating cost item, hang on...', 'load')

    try {
      await mutateChargeItem({
        variables: { input: { uuid: costItem.uuid, newChargeItemUuid: chargeItemUuid } }
      })
      respHandler('Updated successfully.', 'success')
      onUpdate(costItem)
    } catch (error) {
      respHandler(error, 'error')
      console.log(error)
    }
  }

  return (
    <StyledForm>
      <div style={{ marginBottom: '16px' }}>
        <StyledLegend>Original Charge Item</StyledLegend>
        <ChargeItemSelect
          name={costItem.chargeItem && costItem.chargeItem.code}
          value={costItem.chargeItem && costItem.chargeItem.uuid}
          disabled
        />
      </div>
      <div style={{ marginBottom: '16px' }}>
        <StyledLegend>New Charge Item</StyledLegend>
        <ChargeItemSelect onChange={onChange} value={chargeItemUuid} />
      </div>
      <Button
        type="primary"
        loading={loading}
        onClick={handleUpdate}
        disabled={!!costItem.isDeleted}
      >
        Update
      </Button>
    </StyledForm>
  )
})

export default withApollo(ChangeChargeItem)
